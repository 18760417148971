<template>
  <loader
    v-if="executeLoader"
  />

  <div class="i5-breadcrumb">
    <ul>
      <li>você está em:</li>
      <li>Áreas da Medicina</li>
    </ul>
  </div>

  <div style="margin-top:25px;">
    <medicine-areas-filter
      @cleanFilter="cleanFilter"
      @showLoader="showLoader"
      @hideLoader="hideLoader"
    />
  </div>

  <medicine-areas-list
    :key="filterCleaner"
    :getMedicineAreasAfterFilter="getMedicineAreasAfterFilter"
    @showLoader="showLoader"
    @hideLoader="hideLoader"
  />
</template>
<script>
import MedicineAreasList from '@/components/medicineAreas/MedicineAreasList.vue';
import MedicineAreasFilter from '@/components/medicineAreas/MedicineAreasFilter.vue';
import Loader from '@/components/loader/Loader.vue';

export default {
  name: "MedicineAreasView",

  components: {
    MedicineAreasList,
    MedicineAreasFilter,
    Loader
  },

  data() {
    return {
      filterCleaner: 0,
      getMedicineAreasAfterFilter: false,
      executeLoader: false,
    }
  },

  methods: {
    cleanFilter() {
      this.getMedicineAreasAfterFilter = true;
      this.filterCleaner++
    },

    showLoader() {
      return this.executeLoader = true;
    },

    hideLoader() {
      return this.executeLoader = false;
    }
  }
}
</script>