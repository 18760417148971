<template>
    <div class="i5-modal i5-modal-ativo">
        <div class="i5-modal-wrap in">
            <div class="i5-modal-fechar" title="fechar" @click.prevent="closeModal"><i class="fas fa-times"></i></div>
            <div class="i5-modal-header">{{ user.id ? 'Atualizar usuário' : 'Adicionar novo usuário' }}</div>
            <div class="i5-modal-content">
                
                <div class="form-grid">
                    <div class="input-12 required">
                        <label for="user_name">Nome</label>
                        <input type="text" name="user_name" id="user_name" v-model="formData.name"/>
                    </div>
                </div>
                <div class="form-grid">
                    <div class="input-12 required">
                        <label for="user_email">E-mail</label>
                        <input type="text" name="user_email" id="user_email" v-model="formData.email" />
                    </div>
                </div>
                <div class="form-grid">
                    <div class="input-12">
                        <label for="user_expiration_date">Data de expiração</label>
                        <input type="date" name="user_expiration_date" id="user_expiration_date" v-model="formData.expiration_date" />
                    </div>
                </div>

                <div v-if="user.id">
                    <div class="form-grid">
                        <div class="input-12">
                            <label for="user_password">Nova Senha</label>
                            <input type="text" name="user_name" id="user_password" v-model="formData.password"/>
                        </div>
                    </div>
                    <div class="form-grid">
                        <div class="input-12">
                            <label for="user_password_confirmation">Confirmar Nova Senha</label>
                            <input type="text" name="user_email" id="user_password_confirmation" v-model="formData.password_confirmation" />
                        </div>
                    </div>
                    <div class="form-grid">
                        <input
                            type="checkbox"
                            name="active"
                            id="active"
                            :checked="formData.active"
                            v-model="formData.active"
                        />
                        <label for="active">
                            <span></span>
                            <span>Ativo</span>
                        </label>
                    </div>
                </div>

            </div>
            
            <div class="i5-modal-controls">
                <div class="i5-buttons i5-buttons-right">
                <button @click.prevent="user.id == null ? createUser() : updateUser(user.id)" class="button adicionar waves-effect">{{ user.id == null ? 'Salvar' : 'Atualizar' }}</button>
                <button @click.prevent="closeModal" class="button default cancelar waves-effect">Cancelar</button>
                </div>
            </div>
        </div>
        <loader
            v-if="executeLoader"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import Loader from '@/components/loader/Loader.vue';

export default {
    name: 'CreateUpdateUserModal',

    props: {
        user:{
            id: null,
            name: null,
            email: null,
            expiration_date: null,
            active: 0,
        }
    },

    data() {
        return {
            formData: {
                id: null,
                name: null,
                email: null,
                expiration_date: null,
                active: 0,
            },
            executeLoader: false,
        }
    },
    
    mounted() {
        this.fetchUserData()
    },
    
    methods: {
        ...mapActions([
            'storeUserAction',
            'updateUserAction',
        ]),

        defaultExpirationDateValue() {
            const daysToExpire = 180;
            const currentDate = new Date();
            const expirationDate = new Date();
            expirationDate.setDate(currentDate.getDate() + daysToExpire);
            const year = expirationDate.getFullYear();
            const month = (expirationDate.getMonth() + 1).toString().padStart(2, '0');
            const day = expirationDate.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        },

        fetchUserData() {
            this.formData.id = this.user.id;
            this.formData.name = this.user.name;
            this.formData.email = this.user.email;
            this.formData.expiration_date = this.user.id ? this.user.expiration_date : this.defaultExpirationDateValue();
            this.formData.active = Number(this.user.active);
        },


        closeModal(reload = null) {
            this.$emit('closeModal', reload)
        },

        async createUser(){
            if(!this.formData.name || !this.formData.email){
                return alert("Nome e e-mail são abrigatórios");
            }

            try{    
                this.executeLoader = true;
                await this.storeUserAction(this.formData);
                this.toast('Usuário cadastrado com sucesso.', 'success');
                return this.closeModal(true);
            }catch(error){
                //this.closeModal(true);
                console.log(error);
                const message = error.response.data.message?? 'Houve um erro ao cadastrar o usuário.';
                return this.toast(message, 'danger');
            }finally{
                this.executeLoader = false;
            }
        },

        async updateUser(user_id){
            if(!this.formData.name || !this.formData.email){
                return alert("Nome e e-mail são abrigatórios");
            }

            if(this.formData.password !== this.formData.password_confirmation){
                return alert("Senha e Confirmação de Senha não são iguais")
            }

            if((this.formData.password && this.formData.password.length < 8) || (this.formData.password_confirmation && this.formData.password_confirmation.length < 8)){
                return alert("A senha precisa ter no minimo 8 caracteres")
            }

            this.formData.active = this.formData.active == true ? 1 : 0;
            try{    
                this.executeLoader = true;
                await this.updateUserAction(this.formData);
                this.toast('Usuário atualizado com sucesso.', 'success');
                return this.closeModal(true);
            }catch(error){
                //this.closeModal(true);
                console.log(error);
                const message = error.response.data.message?? 'Houve um erro ao atualizar o usuário.';
                return this.toast(message, 'danger');
            }finally{
                this.executeLoader = false;
            }

        },

        toast(text, type, description = '') {
            return createToast(
                {
                    title: text,
                    description: description,
                },
                {
                    type: type,
                    transition: 'zoom',
                    timeout: 3000,
        })
        }
    }

}
</script>