<template>
  <label for="specialties">Instituição: </label>
  <select
    id="specialties"
    name="specialties"
    v-model="formData.institution_id"
    @change="sendInstitutionId"
  >
    <option :value="institution">Escolha...</option>
    <option :value="institution.id" v-for="(institution, key) in institutionsState.institutions" :key="key">
      {{ institution.name }}
    </option>
  </select>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  props: {
    recievedInstitutionId: 0,
    filter: 0,
  },

  emits: {
    sendInstitutionId: null,
  },

  mounted() {
    if (Object.keys(this.institutionsState.institutions).length == 0) {
      this.getInstituions();
    }

    this.fetchRecievedData();
  },

  data() {
    return {
      formData: {
        institution_id: 0,
      },
      institution: 0,
    }
  },

  computed: {
    ...mapState({
      institutionsState: state => state.institutions,
    }),
  },
  
  methods: {
    ...mapActions([
      'getInstitutionsAction',
    ]),

    fetchRecievedData() {      
      this.formData.institution_id = this.recievedInstitutionId;
    },

    sendInstitutionId() {
      this.$emit('sendInstitutionId', this.formData.institution_id);
    },

    async getInstituions() {
      try {
        return await this.getInstitutionsAction();

      } catch(error) {
        return this.errors = error;
      }
    },
  },
}
</script>