<template>
  <div class="i5-controls">
    <div class="i5-buttons i5-buttons-superior">
      <button @click.prevent="openModalCreateUpdateSpecialty" class="button adicionar waves-effect">Adicionar novo</button>
    </div>
  </div>
  <div class="build-content i5_animated i5_fadeInRight">
    <div class="build-datagrid-default">
      <ul>
        
        <li v-for="(specialty, key) in specialtiesState.specialties" :key="key">
          <div>
            <div class="info">
              <div>
                <span>Título:</span>
                <span>{{ specialty.name }}</span>
              </div>
            </div>
      
            <div class="i5-controls-default">
              <button @click.prevent="openModalCreateUpdateSpecialty(specialty)" title="Editar" class="editar waves-effect"><i class="fas fa-pencil-alt"></i> Editar</button>
              <button @click.prevent="showThemesModal(specialty.id, specialty.name)" title="Visualizar Tags" class="editar waves-effect"><i class="fas fa-eye"></i> Visualizar Temas</button>
            </div>
          </div>
        </li>

      </ul>
    </div>
  </div>
  
  <create-update-specialty-modal
    v-if="modalCreateUpdateSpecialty == true"
    :specialty="specialty"
    @closeModal="closeModalCreateUpdateSpecialty"
  />

  <themes-modal
    v-if="themesModal == true"
    :specialtyId="specialtyId"
    :specialtyName="specialtyName"
    @closeModal="closeThemesModal"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

import CreateUpdateSpecialtyModal from '@/components/specialties/CreateUpdateSpecialtyModal.vue';
import ThemesModal from '@/components/specialties/ThemesModal.vue';

export default {
  name: 'SpecialtiesListComponent',

  props: {
    getSpecialtiesAfterFilter: false,
  },

  emits: {
    showLoader: null,
    hideLoader: null,
  },

  data() {
    return {
      formData: {
        name: '',
      },
      errors: null,
      themesModal: false,
      modalCreateUpdateSpecialty: false,
      specialtyId: null,
      specialtyName: '',
      specialty: {},

    }
  },

  components: {
    CreateUpdateSpecialtyModal,
    ThemesModal,
  },

  mounted() {
    if (this.specialtiesState.specialties.length == 0 || this.getSpecialtiesAfterFilter) {
      this.getSpecialties()
    }
  },

  computed: {
    ...mapState({
      specialtiesState: state => state.specialties,
    }),
  },

  methods: {
    ...mapActions([
      'getSpecialtiesAction',
      'destroySpecialtyAction',
    ]),

    async getSpecialties() {
      try {
        this.showLoaderEmiter();
        return await this.getSpecialtiesAction();
      } catch(error) {
        this.toast('Houve um erro ao buscar as especialidades', 'danger');
      } finally {
        this.hideLoaderEmiter();
      }
    },

    async destroySpecialty(id) {
      try {
        this.showLoaderEmiter();
        await this.destroySpecialtyAction(id)
        this.toast('Especialidade exlcuída com sucesso', 'success');
        return this.getSpecialties();
      } catch(error) {
        return this.toast('Houve um erro ao excluir a especialidade', 'danger');
      } finally {
        this.hideLoaderEmiter();
      }
    },

    openModalCreateUpdateSpecialty(specialty) {      
      this.specialty = specialty;
      this.modalCreateUpdateSpecialty = true;
    },

    closeModalCreateUpdateSpecialty(reload = null) {
      this.modalCreateUpdateSpecialty = false;
      this.specialty = {};

      if (reload) {
        return this.getSpecialties();
      }
    },

    showThemesModal(specialtyId, specialtyName) {
      this.themesModal = true;
      this.specialtyId = specialtyId;
      this.specialtyName = specialtyName
    },

    closeThemesModal() {
      this.themesModal = false;
      this.specialtyId = null;
      this.specialtyName = '';
    },

    showLoaderEmiter() {
      return this.$emit('showLoader')
    },

    hideLoaderEmiter() {
      return this.$emit('hideLoader');
    },

    toast(text, type, description = '') {
      return createToast(
        {
          title: text,
          description: description,
        },
        {
          type: type,
          transition: 'zoom',
          timeout: 3000,
      })
    }
  }
}
</script>