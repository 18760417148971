<template>
  <label for="medicineArea">Área da Medicina: </label>
  <select
    id="medicineAreas"
    name="medicineAreas"
    v-model="formData.medicine_area_id"
    @change="sendMedicineAreaId"
  >
    <option :value="medicineArea">Escolha...</option>
    <option :value="medicineArea.id" v-for="(medicineArea, key) in medicineAreasState.medicineAreas" :key="key">
      {{ medicineArea.name }}
    </option>
  </select>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  props: {
    recievedMedicineAreaId: 0,
    filter: 0,
    selectKey: 0,
  },

  emits: {
    getMedicineAreaId: null,
    sendMedicineAreaId: null,
  },

  mounted() {
    if (Object.keys(this.medicineAreasState.medicineAreas).length == 0) {
      this.getMedicineAreas();
    }

    this.fetchRecievedData();
  },

  data() {
    return {
      formData: {
        medicine_area_id: 0,
      },
      medicineArea: 0,
    }
  },

  computed: {
    ...mapState({
      medicineAreasState: state => state.medicineAreas,
    }),
  },
  
  methods: {
    ...mapActions([
      'getMedicineAreasAction',
    ]),

    fetchRecievedData() {
      this.formData.medicine_area_id = this.recievedMedicineAreaId;
    },

    sendMedicineAreaId() {
      this.$emit('sendMedicineAreaId', this.formData.medicine_area_id, this.selectKey);
    },

    async getMedicineAreas() {
      try {
        const medicineAreas = await this.getMedicineAreasAction();
        
        return this.medicineAreasLength = medicineAreas.data.length;

      } catch(error) {
        return this.errors = error;
      }
    },
  },
}
</script>