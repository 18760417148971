import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/js/materialize.min.js';
import Toast from './components/toast/Toast.vue';
import Loader from './components/loader/Loader.vue';
import VueMultiselect from 'vue-multiselect'
import Confirmation from './components/confirmation/ConfirmationModal.vue';
// import './registerServiceWorker'

// import VueCookies from 'vue-cookies';

let app = createApp(App);
app.component('Toast', Toast);
app.component('Loader', Loader);
app.component('VueMultiselect', VueMultiselect)
app.component('Confirmation', Confirmation)
// app.use(VueCookies);
app.use(store).use(router).mount('#app')
