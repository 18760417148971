<template>
  <div class="form-grid">
    <div class="input-12">
      <div class="input-custom">
        <span style="font-size:18px;font-weight:bold;display:block;margin:0 0 15px;">Filtre por usuário:</span>
      </div>
    </div>

    <div class="input-6">
      <label for="name_id">Nome:</label>
      <input type="text" name="name" id="name_id" v-model="filters.name" />
    </div>

    <div class="input-6">
      <label for="email_id">E-mail:</label>
      <input type="text" name="email" id="email_id" v-model="filters.email" />
    </div>
  </div>
  
  <div class="form-grid">
    <div class="input-6">
      <label for="status_id">status:</label>
      <select
        id="status_id"
        name="active"
        v-model="filters.active"
      >
        <option value="">Todos</option>
        <option value="1">Ativo</option>
        <option value="0">Desativado</option>
      </select>
    </div>
  </div>

  <div class="form-grid">
    <div class="input-12">
      <div class="i5-buttons i5-buttons-right">
        <button :disabled="validations == false" @click.prevent="search" class="button confirmar waves-effect">Filtrar</button>
        <button @click.prevent="cleanSearch" class="button default waves-effect">Limpar filtros</button>
      </div>
    </div>
  </div>
</template>
  
<script>
import { mapActions } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default {
  name: 'UsersFilter',

  props: {
    filter: {
      name: '',
      email: '',
      active: '',
    },
  },

  emits: {
    cleanFilter: null,
    showLoader: null,
    hideLoader: null,
    fillFiltro: null,
  },

  data() {
    return {
      filters: {
        name: '',
        active: '',
        email: '',
      },
      cleanFilter: 0,
      validations: true,
    }
  },

  watch: {
    filters: {
      handler: function(param) {
        if (param.name != '') {
          return this.validations = true;
        }
      },
      deep: true,
    }
  },

  mounted() {
    
  },

  methods: {
    ...mapActions([
      'searchUserAction',
    ]),

    async search() {
      try {
        this.showLoaderEmiter();
        this.$emit('fillFiltro', this.filters);
        const response = await this.searchUserAction(this.filters);
        if (response.data.length == 0) {
          this.toast('Não encontramos o usuário filtrado', 'warning');
          //return this.cleanSearch();
        }
        
      } catch(error) {
        return this.toast('Houve um erro ao buscar os usuário', 'danger');
      
      } finally {
        this.hideLoaderEmiter();
      }
    },

    cleanSearch() {
      this.cleanFilter++;
      this.filters.name = '';
      this.filters.email = '';
      this.filters.active = '';
      return this.$emit('cleanFilter');
    },

    showLoaderEmiter() {
      return this.$emit('showLoader')
    },

    hideLoaderEmiter() {
      return this.$emit('hideLoader');
    },

    toast(text, type, description = '') {
      return createToast(
        {
          title: text,
          description: description,
        },
        {
          type: type,
          transition: 'zoom',
          timeout: 3000,
      })
    }
  }
}
</script>