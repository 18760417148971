import axios from 'axios';
import { URI_BASE } from '@/config/api';

const routes = {
  login: 'user/login',
  dashboard: 'user/dashboard',
  logout: 'user/logout',
};

export default {
  getToken() {
    return localStorage.getItem(TOKEN);    
  },

  async loginAction({commit}, params) {    
    return await axios.post(`${URI_BASE}${routes.login}`, params)
  },

  async logoutAction({commit}, params) {
    return await axios.post(`${URI_BASE}${routes.logout}`, params)
  }
}