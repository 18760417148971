<template>
  <div class="i5-modal i5-modal-ativo">
    <div class="i5-modal-wrap in">
      <div class="i5-modal-fechar" title="fechar" @click.prevent="closeModal"><i class="fas fa-times"></i></div>
      <div class="i5-modal-header">{{ test.id == null ? 'Nova Prova' : `Atualizar Prova: ${name}` }}</div>
      <div class="i5-modal-content">
        
        <div class="form-grid">
          <div class="input-12 required">
            <select-institutions
              :recievedInstitutionId="test.id != null ? test.institution_id : formData.institution_id"
              @sendInstitutionId="getInstitutionId"
              :key="cleanFilter"
            />

            <select-years
              @sendYearId="getYearId"
              :recievedYearId="test.id != null ? test.year_id : formData.year_id"
              :recievedInstitutionId="test.id != null ? test.institution_id : formData.institution_id"
              :key="reduceYears"
            />
          </div>
        </div>
      </div>
      
      <div class="i5-modal-controls">
        <div class="i5-buttons i5-buttons-right">
          <button
            :disabled="validations == false"
            @click.prevent="test.id == null ? storeTest() : updateInstitution(test.id)"
            class="button adicionar waves-effect">
            {{ test.id == null ? 'Salvar' : 'Atualizar' }}
          </button>
          <button @click.prevent="closeModal" class="button default cancelar waves-effect">Cancelar</button>
        </div>
      </div>
    </div>
  </div>

  <loader
    v-if="executeLoader"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { TOKEN } from '@/config/api';

import Loader from '@/components/loader/Loader.vue';
import SelectInstitutions from '@/components/institutions/SelectInstitutions.vue';
import SelectYears from '@/components/years/SelectYears.vue';

export default {
  name: 'CreateUpdateInstitutionsModal',

  props: {
    test: {
      id: null,
      institution_id: 0,
      year_id: 0,
      name: '',
    },
  },

  components: {
    Loader,
    SelectInstitutions,
    SelectYears,
  },

  emits: {
    closeModal: null,
  },

  computed: {
    ...mapState({
      institutionsState: state => state.institutions,
      yearsState: state => state.years,
    }),
  },

  data() {
    return {
      formData: {
        institution_id: 0,
        year_id: 0,
      },
      name: '',
      executeLoader: false,
      validations: false,
      cleanFilter: 0,
      reduceYears: 0,
      fromTest: 1,
    }
  },

  watch: {
    formData: {
      handler: function(param) {
        if (param.institution_id != undefined && param.institution_id != 0) {
          this.validations = true;
        }

        if (param.year_id != undefined && param.year_id != 0) {
          return this.validations = true;
        }

        this.validations = false;
      },
      deep: true,
    }
  },

  mounted() {
    this.fetchTestData();
  },

  methods: {
    ...mapActions([
      'storeTestAction',
      'updateTestAction',
      'destroyTestAction',
      'getInstitutionsAction',
      'getYearsAction',
    ]),

    fetchTestData() {
      this.formData.year_id = this.test.year_id;
      this.formData.institution_id = this.test.institution_id;
      this.name = this.test.name;
    },

    async storeTest() {
      try {
        this.executeLoader = true;
        await this.storeTestAction(this.formData);
        return this.$store.commit('toast', {execute: true, text: 'Prova cadastrada com sucesso', type: 'success'});
      } catch(error) {
        if (error.response.status == 401) {
          return this.expiredSession()
        }

        return this.$store.commit('toast', {execute: true, text: 'Houve um erro ao cadastrar a prova', type: 'danger'})
      } finally {
        this.executeLoader = false;
        this.closeModal(true);   
      }
    },

    async updateInstitution(id) {
      try {
        this.executeLoader = true;
        await this.updateTestAction({id, data: this.formData});
        this.$store.commit('toast', {execute: true, text: 'Prova alterada com sucesso', type: 'success'});
      } catch(error) {
        if (error.response.status == 401) {
          return this.expiredSession();
        }

        return this.$store.commit('toast', {execute: true, text: 'Houve um problema para alterar a prova', type: 'danger'});
      } finally {
        this.executeLoader = false;
        return this.closeModal(true);
      }
    },

    closeModal(reload = null) {
      this.$emit('closeModal', reload)
    },

    getYearId(yearId) {
      return this.formData.year_id = yearId;
    },

    getInstitutionId(institutioId) {
      this.reduceYears++;
      return this.formData.institution_id = institutioId;
    },

    expiredSession() {
      this.$store.commit('toast', {execute: true, text: 'Sua sessão expirou! Faça login novamente', type: 'warning'});
      window.localStorage.removeItem(TOKEN)
      this.$router.push({name: 'home'})
    }
  }
}
</script>