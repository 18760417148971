<template>
  <div class="i5-controls">
    <div class="i5-buttons i5-buttons-superior">
      <button @click.prevent="openModalCreateUpdateMockTests" class="button adicionar waves-effect">Criar simulado</button>
    </div>
  </div>

  <div class="build-content i5_animated i5_fadeInRight">
    <div class="build-datagrid-cards build-datagrid-cards-3">
      <ul class="list-column-md">
        <li v-for="(mockTest, key) in sortMockTest" :key="key">
          <div>
            <div class="info">
              <div>
                <router-link :to="{name: 'simulado', params: {id: mockTest.id,}}">
                <div class="title"><span style="font-size: 12px; color: #7c7c7c;">SIMULADO</span><br> {{ `${mockTest.name}` }}</div>
                  Clique para visualizar as questões
                </router-link>
              </div>
            </div>
            <div class="i5-controls-default">
              <button @click.prevent="openModalCreateUpdateMockTests(mockTest)" title="Editar" class="editar waves-effect"><i class="fas fa-pencil-alt"></i> Editar</button>
              <button @click.prevent="openConfirmationModal(mockTest)" title="Remover" class="remover waves-effect"><i class="fas fa-times"></i> Excluir</button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <create-update-mock-tests-modal 
    v-if="modalCreateUpdateMockTests == true"
    :mockTest="mockTest"
    @closeModal="closeModalCreateUpdateMockTest"
  />

  <confirmation-modal
    v-if="executeConfirmationModal"
    :text="confirmationText"
    :id="mockTestId"
    @destroy="destroyConfirmation"
    @closeModal="closeConfirmationModal"
  />

</template>
<script>
  import { mapActions, mapState } from 'vuex';
  import CreateUpdateMockTestsModal from './CreateUpdateMockTestsModal.vue';
  import ConfirmationModal from '@/components/confirmation/ConfirmationModal.vue';

  export default {
    name: 'MockTestsList',
  
    props: {},
  
    emits: {
      showLoader: null,
      hideLoader: null,
    },
  
    data() {
      return {
        modalCreateUpdateMockTests: false,
        mockTest: {},
        executeConfirmationModal: false,
        mockTestId: null,
      }
    },
  
    components: {
      CreateUpdateMockTestsModal,
      ConfirmationModal
    },
  
    mounted() {
      this.getMockTests();
    },
  
    computed: {
      ...mapState({
        mockTestsState: state => state.mockTests,
      }),
      sortMockTest() {
        if(this.mockTestsState.mockTests.length){
          return this.mockTestsState.mockTests.sort((a,b)=>{
            if (a.id > b.id) {
              return -1;
            } else {
              return 1;
            }
          });
        }
        return [];
      }
    },
  
    methods: {

      ...mapActions([
        'getMockTestsAction',
        'destroyMockTestAction'
      ]),

      async getMockTests(){
        try {
          this.showLoaderEmiter();
          return await this.getMockTestsAction();
        } catch(error) {
          console.log(error);
        } finally {
          this.hideLoaderEmiter();
        }
      },

      openModalCreateUpdateMockTests(mockTest) {      
        this.mockTest = mockTest;
        this.modalCreateUpdateMockTests = true;
      },

      closeModalCreateUpdateMockTest(reload = null) {
        this.modalCreateUpdateMockTests = false;
        this.mockTest = {};

        if (reload) {
          return this.getMockTests();
        }
      },

      openConfirmationModal(mockTest){
        this.executeConfirmationModal = true;
        this.mockTestId = mockTest.id;
        this.confirmationText = `Deseja mesmo excluir o simulado ${mockTest.name}?`;
      },

      closeConfirmationModal() {
        this.executeConfirmationModal = false;
      },

      async destroyConfirmation(id) {
        try{
          return await this.destroyMockTestAction(id);
        } catch(error){
          alert(error);
          console.log(error)
        } finally{
          this.closeConfirmationModal();
          return this.getMockTests();
        }
      },

      showLoaderEmiter() {
        return this.$emit('showLoader')
      },

      hideLoaderEmiter() {
        return this.$emit('hideLoader');
      },
    }
  }
</script>