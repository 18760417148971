<template>
  <label for="years">Anos: </label>
  <select
    id="years"
    name="years"
    v-model="formData.year_id"
    @change="sendYearId"
  >
    <option :value="year">Escolha...</option>
    <option :value="year.id" v-for="(year, key) in recievedInstitutionId == 0 || recievedInstitutionId == undefined ? yearsState.years : reducedYearsByInstitution" :key="key">
      {{ `${year.name} - ${year.institutionName}` }}
    </option>
  </select>

  <loader
    v-if="executeLoader == true"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Loader from '@/components/loader/Loader.vue';

export default {
  props: {
    recievedYearId: 0,
    recievedInstitutionId: 0,
    filter: 0,
  },

  emits: {
    sendYearId: null,
    cleanFilter: null,
  },

  components: {
    Loader
  },

  mounted() {
    if (Object.keys(this.yearsState.years).length == 0) {
      this.getYears();
    }

    if (this.recievedYearId) {
      this.fetchRecievedData();
    }
    
    if(Object.keys(this.yearsState.years).length != 0 && this.recievedInstitutionId != 0 && this.recievedInstitutionId != undefined) {
      this.reduceYearsByInstitution();
    }
  },

  data() {
    return {
      formData: {
        year_id: 0,
      },
      year: 0,
      reducedYearsByInstitution: [],
      executeLoader: false,
    }
  },

  computed: {
    ...mapState({
      yearsState: state => state.years,
    }),
  },
  
  methods: {
    ...mapActions([
      'getYearsAction',
    ]),

    fetchRecievedData() {
      this.formData.year_id = this.recievedYearId;
    },

    reduceYearsByInstitution() {
      this.yearsState.years.map(year => {
        if (year.institution_id == this.recievedInstitutionId) {
          this.reducedYearsByInstitution.push(year);
        }
      });
    },

    cleanSearch() {
      this.cleanFilter++;
      this.filters.name = '';
      return this.$emit('cleanFilter');
    },

    sendYearId() {
      this.$emit('sendYearId', this.formData.year_id);
    },

    async getYears() {
      try {
        this.executeLoader = true;
        await this.getYearsAction();

        if(this.recievedInstitutionId != 0 && this.recievedInstitutionId != undefined) {
          this.reduceYearsByInstitution();
        }
      } catch(error) {
        return this.errors = error;
      } finally {
        this.executeLoader = false;
      }
    },
  },
}
</script>