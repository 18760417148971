<template>
    <loader 
      v-if="executeLoader"
    />
    <div class="i5-breadcrumb">
      <ul>
        <li>você está em:</li>
        <li>Simulados</li>
      </ul>
    </div>

    <mock-tests-list
      @showLoader="showLoader"
      @hideLoader="hideLoader"
    />
  
  </template>
  
  <script>
  import Loader from '@/components/loader/Loader.vue';
  import MockTestsList from '@/components/mockTests/MockTestsList.vue';
  
  export default {
    name: "MockTests",
  
    data() {
      return {
        executeLoader: false,
      }
    },
  
    components: {
      Loader,
      MockTestsList
    },
  
    methods: {
  
      showLoader() {
        return this.executeLoader = true;
      },

      hideLoader() {
        return this.executeLoader = false;
      }
  
    }
  }
  
</script>