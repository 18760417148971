<template>
    <div class="i5-controls">
        <div class="i5-buttons i5-buttons-superior">
            <button @click.prevent="openModalCreateUpdateUser" class="button adicionar waves-effect">Adicionar novo usuário</button>
        </div>
    </div>

    <div class="build-content i5_animated i5_fadeInRight">
        <div class="build-datagrid-default">
            <ul>
                
                <li v-for="(user, key) in themesState.users" :key="key">
                <div>
                    <div class="info">
                        <div>
                            <span>ID:</span>
                            <span>{{ user.id }}</span>
                        </div>

                        <div>
                            <span>Nome:</span>
                            <span>{{ user.name }}</span>
                        </div>
                        <div>
                            <span>E-mail:</span>
                            <span>{{ user.email }}</span>
                        </div>
                        <div>
                            <span>Status:</span>
                            <span>
                                {{ user.active == 1 ? 'Ativo' : 'Desativado' }}
                            </span>
                        </div>
                    </div>
            
                    <div class="i5-controls-default">
                    <button @click.prevent="openModalCreateUpdateUser(user)" title="Editar" class="editar waves-effect"><i class="fas fa-pencil-alt"></i> Editar</button>
                    <button @click.prevent="destroyUser(user.id, user)" title="Remover" class="remover waves-effect"><i class="fas fa-times"></i> Excluir</button>
                    </div>
                </div>
                </li>

            </ul>
        </div>
    </div>

    <create-update-user-modal
        v-if="modalCreateUpdateUser == true"
        :user="user"
        @closeModal="closeModalCreateUpdateUser"
    />

</template>

<script>
import { createToast } from 'mosha-vue-toastify';
import { mapActions, mapState } from 'vuex';
import CreateUpdateUserModal from './CreateUpdateUserModal.vue';

export default {
    name: "UsersList",

    emits: {
        showLoader: null,
        hideLoader: null,
        closeModal: null,
        openModal: null,
    },

    data(){
        return {
            modalCreateUpdateUser: false,
            user: {},
        }
    },

    components: {
        CreateUpdateUserModal
    },  

    mounted(){
        this.getUsers();
    },    

    computed: {
        ...mapState({
            themesState: state => state.users,
        }),
    },

    methods: {
        ...mapActions([
            'getUsersAction',
            'destroyUserAction',
        ]),

        async getUsers(){
            return this.getUsersAction();
        },

        openModalCreateUpdateUser(user){
            this.user = user;
            this.modalCreateUpdateUser = true;
            this.$emit('openModal');
        },

        closeModalCreateUpdateUser(reload = null) {
            this.modalCreateUpdateUser = false;
            this.user = {};

            if (reload) {
                return this.$emit('closeModal');
            }
        },

        async destroyUser(id, user) {
            try {
                const text = `Tem certeza que quer excluir o id: ${user.id}, nome: ${user.name}, email: ${user.email}`;
                if(confirm(text)){
                    this.showLoaderEmiter();
                    await this.destroyUserAction(id)
                    this.toast('Usuário excluído com sucesso', 'success');
                    return this.getUsers();
                }
                return;
            } catch(error) {
                console.log(error);
                return this.toast('Houve um erro ao excluir o tema', 'danger');
            } finally {
                this.hideLoaderEmiter();
            }
        },

        showLoaderEmiter() {
            return this.$emit('showLoader')
        },

        hideLoaderEmiter() {
            return this.$emit('hideLoader');
        },

        toast(text, type, description = '') {
            return createToast(
                {
                title: text,
                description: description,
                },
                {
                type: type,
                transition: 'zoom',
                timeout: 3000,
            })
        }
    }
}
</script>