<template>
  <div class="i5-controls">
    <div class="i5-buttons i5-buttons-superior">
      <button @click.prevent="openModalCreateUpdateQuestion" class="button adicionar waves-effect">Adicionar nova questão</button>
    </div>
  </div>

<div v-if="questionsState.questions == undefined || questionsState.questions.length == 0">
    Nenhuma questão cadastrada
  </div>

  <div class="build-content i5_animated i5_fadeInRight">
    <div class="build-datagrid-default">
      <ul>
        <li v-for="(question, key) in questionsState.questions" :key="key">
          <div>
            <div class="info">
              <div>
                <span>ID:</span>
                <span>{{ question.id }}</span>
              </div>

              <div>
                <span>Questão:</span>
                <span maxlength="100">{{ question.question }}</span>
              </div>
              <div>
                <span>Resposta Correta:</span>
                <span maxlength="100">{{ question.alternative_name }}</span>
              </div>
              <div>
                <span>Instituição:</span>
                <span maxlength="100">{{ question.institution_name }}</span>
              </div>
              <div>
                <span>Ano:</span>
                <span maxlength="100">{{ question.year_name }}</span>
              </div>
              <div>
                <span>Área da Medicina:</span>
                <span maxlength="100">{{ question.medicine_area_name }}</span>
              </div>
              <div>
                <span>Especialidade:</span>
                <span maxlength="100">{{ question.specialty_name }}</span>
              </div>
              <div>
                <span>Tema:</span>
                <span maxlength="100">{{ question.theme_name }}</span>
              </div>
            </div>
    
            <div class="i5-controls-default">
              <button @click.prevent="openModalCreateUpdateQuestion(question)" title="Editar" class="editar waves-effect"><i class="fas fa-pencil-alt"></i> Editar</button>
              <button @click.prevent="destroyQuestion(question.id)" title="Remover" class="remover waves-effect"><i class="fas fa-times"></i> Excluir</button>
            </div>
          </div>
        </li>

      </ul>
    </div>
  </div>

  <create-update-questions-modal 
    v-if="modalCreateUpdateQuestion == true"
    :question="question"
    @closeModal="closeModalCreateUpdateQuestion"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

import CreateUpdateQuestionsModal from '@/components/questions/CreateUpdateQuestionsModal.vue';

export default {
  name: 'QuestionsListComponent',

  props: {
    getQuestionsAfterFilter: false,
  },

  emits: {
    showLoader: null,
    hideLoader: null,
  },

  data() {
    return {
      formData: {
        name: '',
      },
      errors: null,
      modalCreateUpdateQuestion: false,
      question: {},
    }
  },

  components: {
    CreateUpdateQuestionsModal,
  },

  mounted() {
    if (this.testId != undefined && this.testId != 0) {
      return this.getQuestionsByTestId();
    }

    if (this.questionsState.questions == undefined || this.questionsState.questions.length == 0 || this.getQuestionsAfterFilter) {
      this.getQuestions()
    }
  },

  computed: {
    ...mapState({
      questionsState: state => state.questions,
    }),
  },

  methods: {
    ...mapActions([
      'getQuestionsAction',
      'destroyQuestionAction',
      'getQuestionsByTestIdAction',
    ]),

    async getQuestions() {
      try {
        this.showLoaderEmiter();
        return await this.getQuestionsAction();
      } catch(error) {
        this.toast('Houve um erro ao buscar as questões', 'danger');
      } finally {
        this.hideLoaderEmiter();
      }
    },

    async destroyQuestion(id) {
      try {
        this.showLoaderEmiter();
        await this.destroyQuestionAction(id)
        this.toast('Questão exlcuída com sucesso', 'success');
        return this.getQuestions();
      } catch(error) {
        return this.toast('Houve um erro ao excluir a questão', 'danger');
      } finally {
        this.hideLoaderEmiter();
      }
    },

    openModalCreateUpdateQuestion(question) {      
      this.question = question;
      this.modalCreateUpdateQuestion = true;
    },

    closeModalCreateUpdateQuestion(reload = null) {
      this.modalCreateUpdateQuestion = false;
      this.question = {};

      if (reload) {
        return this.getQuestions();
      }
    },

    showLoaderEmiter() {
      return this.$emit('showLoader')
    },

    hideLoaderEmiter() {
      return this.$emit('hideLoader');
    },

    toast(text, type, description = '') {
      return createToast(
        {
          title: text,
          description: description,
        },
        {
          type: type,
          transition: 'zoom',
          timeout: 3000,
      })
    }
  }
}
</script>