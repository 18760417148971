<template>
  <div class="i5-controls">
    <div class="i5-buttons i5-buttons-superior">
      <button @click.prevent="openModalCreateUpdateYear" class="button adicionar waves-effect">Adicionar novo</button>
    </div>
  </div>

  <div class="build-content i5_animated i5_fadeInRight">
    <div class="build-datagrid-default">
      <ul>
        
        <li v-for="(year, key) in yearsState.years" :key="key">
          <div>
            <div class="info">
              <div>
                <span>Ano:</span>
                <span>{{ year.name }}</span>
              </div>

              <div>
                <span>Instituição:</span>
                <span>{{ year.institutionName }}</span>
              </div>
            </div>
    
            <div class="i5-controls-default">
              <button @click.prevent="openModalCreateUpdateYear(year)" title="Editar" class="editar waves-effect"><i class="fas fa-pencil-alt"></i> Editar</button>
              <!-- <button @click.prevent="openInstitutionsModal(year)" title="Visualizar Instituições" class="editar waves-effect"><i class="fas fa-eye"></i> Visualizar Instituições</button> -->
              <button @click.prevent="destroyYear(year.id)" title="Remover" class="remover waves-effect"><i class="fas fa-times"></i> Excluir</button>
            </div>
          </div>
        </li>

      </ul>
    </div>
  </div>

  <create-update-years-modal 
    v-if="modalCreateUpdateYear == true"
    :year="year"
    @closeModal="closeModalCreateUpdateYear"
  />

  <!-- <institutions-modal
    v-if="InstitutionsModal == true"
    @closeInstitutionsModal="closeInstitutionsModal"
    :year="year"
  /> -->
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

import CreateUpdateYearsModal from '@/components/years/CreateUpdateYearsModal.vue';
import InstitutionsModal from '@/components/institutions/InstitutionsModal.vue';


export default {
  name: 'YearsListComponent',

  props: {
    getYearsAfterFilter: false,
  },

  emits: {
    showLoader: null,
    hideLoader: null,
  },

  data() {
    return {
      formData: {
        name: '',
        institution_id: null,
      },
      errors: null,
      modalCreateUpdateYear: false,
      year: {},
      InstitutionsModal: false,
    }
  },

  components: {
    CreateUpdateYearsModal,
    InstitutionsModal,
  },

  mounted() {
    if (this.yearsState.years.length == 0 || this.getYearsAfterFilter) {
      this.getYears()
    }
  },

  computed: {
    ...mapState({
      yearsState: state => state.years,
    }),
  },

  methods: {
    ...mapActions([
      'getYearsAction',
      'destroyYearAction',
    ]),

    async getYears() {
      try {
        this.showLoaderEmiter();
        await this.getYearsAction();
      } catch(error) {
        return this.toast('Houve um erro ao buscar os anos', 'danger');
      } finally {
        this.hideLoaderEmiter();
      }
    },

    async destroyYear(id) {
      try {
        this.showLoaderEmiter();
        await this.destroyYearAction(id)
        this.toast('Ano exlcuído com sucesso', 'success');
        return this.getThemes();
      } catch(error) {
        return this.toast('Houve um erro ao excluir o ano', 'danger');
      } finally {
        this.hideLoaderEmiter();
      }
    },  

    openModalCreateUpdateYear(year) {
      this.year = year;
      this.modalCreateUpdateYear = true;
    },

    closeModalCreateUpdateYear(reload = null) {
      this.modalCreateUpdateYear = false;
      this.year = {};

      if (reload) {
        return this.getYears();
      }
    },

    openInstitutionsModal(year) {
      this.year = year
      return this.InstitutionsModal = true
    },

    closeInstitutionsModal() {
      this.year = {};
      return this.InstitutionsModal = false;
    },

    showLoaderEmiter() {
      return this.$emit('showLoader')
    },

    hideLoaderEmiter() {
      return this.$emit('hideLoader');
    },

    toast(text, type, description = '') {
      return createToast(
        {
          title: text,
          description: description,
        },
        {
          type: type,
          transition: 'zoom',
          timeout: 3000,
      })
    }
  }
}
</script>