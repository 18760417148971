<template>
  <div class="i5-modal i5-modal-ativo">
    <div class="i5-modal-wrap in">
      <div class="i5-modal-fechar" title="fechar" @click.prevent="closeModal"><i class="fas fa-times"></i></div>
      <div class="i5-modal-header">{{ institution.id == null ? 'Nova Instituição' : 'Atualizar Instituição' }}</div>
      <div class="i5-modal-content">
        
        <div class="form-grid">
          <div class="input-12 required">
            <label for="theme_id">Instituição</label>
            <input type="text" name="institution_name" id="institution_id" v-model="formData.name" />
          </div>
        </div>

      </div>
      
      <div class="i5-modal-controls">
        <div class="i5-buttons i5-buttons-right">
          <button
            :disabled="validations == false"
            @click.prevent="institution.id == null ? createInstitution() : updateInstitution(institution.id)"
            class="button adicionar waves-effect">
            {{ institution.id == null ? 'Salvar' : 'Atualizar' }}
          </button>
          <button @click.prevent="closeModal" class="button default cancelar waves-effect">Cancelar</button>
        </div>
      </div>
    </div>
  </div>

  <loader
    v-if="executeLoader"
  />
</template>

<script>
import { mapActions } from 'vuex';

import Loader from '@/components/loader/Loader.vue';

export default {
  name: 'CreateUpdateInstitutionsModal',

  props: {
    institution: {
      id: null,
      name: '',
    },
  },

  components: {
    Loader,
  },

  emits: {
    closeModal: null,
  },

  data() {
    return {
      formData: {
        id: null,
        name: '',
      },
      executeLoader: false,
      validations: false,
    }
  },

  watch: {
    formData: {
      handler: function(param) {
        if (this.institution.id != null) {
          if (param.id != undefined && param.name != undefined) {
            return this.validations = true;
          }
        }

        if (this.formData.name != undefined) {
          return this.validations = true;
        }
      },
      deep: true,
    }
  },

  mounted() {
    this.fetchInstitutionData()
  },

  methods: {
    ...mapActions([
      'storeInstitutionAction',
      'updateInstitutionAction',
      'destroyInstitutionAction',
    ]),

    fetchInstitutionData() {
      this.formData.id = this.institution.id;
      this.formData.name = this.institution.name;
    },

    async createInstitution() {
      try {
        this.executeLoader = true;
        await this.storeInstitutionAction(this.formData);
        this.toast('Instituição cadastrada com sucesso.', 'success');
        this.executeLoader = false;
        return this.closeModal(true);
      } catch(error) {
        this.executeLoader = false;
        return this.toast('Houve um erro ao cadastrar a instituição.', 'danger');
      }
    },

    async updateInstitution(id) {
      try {
        this.executeLoader = true;
        await this.updateInstitutionAction({id, data: this.formData});
        this.toast('Instituição alterada com sucesso', 'success');
        this.executeLoader = false;
        return this.closeModal(true);
      } catch(error) {        
        this.executeLoader = false;
        this.toast('Houve um problema para alterar a instituição', 'danger');
      }
    },

    closeModal(reload = null) {
      this.$emit('closeModal', reload)
    },
  }
}
</script>