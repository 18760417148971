<template>
  <form>
    <div class="form-grid" v-if="errors != null">
      <div class="input-12">
        {{ errors.message }}
      </div>
    </div>

    <div class="form-grid">
      <div class="input-12">
        <input type="email" v-model="formData.email" placeholder="E-mail" required />
      </div>
    </div>

    <div class="form-grid">
      <div class="input-12">
        <input type="password" v-model="formData.password" placeholder="Senha" required />
      </div>
    </div>
  </form>

  <div class="form-grid">
    
  </div>

  <div class="form-grid">
    <div class="input-12">
      <button class="btn-large waves-effect waves-light" @click="login">Entrar</button>
    </div>
  </div>

  <loader
    v-if="executeLoader"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { TOKEN } from '@/config/api';

import Loader from '@/components/loader/Loader.vue';

const STATUS_200 = 200;

export default {
  data() {
    return {
      formData: {
        email: '',
        password: ''
      },
      errors: null,
      executeLoader: false,
    }
  },

  components: {
    Loader,
  },
  
  computed: {
    ...mapState({
      
    }),
  },

  methods: {
    ...mapActions([
        'loginAction',
    ]),

    async login() {
      this.cleanVariables();

      try {
        this.executeLoader = true;
        const response = await this.loginAction(this.formData);

        if (response.status == STATUS_200) {
          window.localStorage.setItem(TOKEN, response.data.token);
          window.localStorage.setItem('name', response.data.name);
          window.localStorage.setItem('email', response.data.email);
          window.localStorage.setItem('id', response.data.id);

          return this.$router.push({ name: 'dashboard' });
        }
      } catch(error) {
        this.errors = error.response.data;
      } finally {
        this.executeLoader = false;
      }
    },

    cleanVariables() {
      this.errors = null;
    },
  }
}
</script>