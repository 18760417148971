<template>
  <div class="i5-modal i5-modal-ativo">
    <div class="i5-modal-loading" style="display:none;">
      <div class="preloader-wrapper big active">
        <div class="spinner-layer">
          <div class="circle-clipper left"><div class="circle"></div></div>
          <div class="gap-patch"><div class="circle"></div></div>
          <div class="circle-clipper right"><div class="circle"></div></div>
        </div>
      </div>
    </div>

    <div class="i5-modal-wrap in">
      <div class="i5-modal-fechar" title="fechar" @click.prevent="closeModal"><i class="fas fa-times"></i></div>
      <div class="i5-modal-header">Temas vinculados a espcialidade: {{ specialtyName }}</div>
      <div class="i5-modal-content">
        <div class="i5-contents i5-contents-list2">
          <ul>
            <li v-for="(tag, key) in tags" :key="key">
              {{ tag.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="i5-modal-controls">
        <div class="i5-buttons i5-buttons-right">
          <router-link :to="{name: 'themes', query: {specialtyId}}" class="button adicionar waves-effect">Ir para Temas</router-link>
          <button @click.prevent="closeModal" class="button default cancelar waves-effect">Fechar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default {
  props: {
    specialtyId: null,
    specialtyName: '',
  },

  emits: {
    closeModal: null,
  },

  data() {
    return {
      formData: {
        name: '',
        specialty_id: null,
      },
      errors: null,
      success: '',
      tags: null,
    }
  },

  mounted() {
    if(this.specialtyId == null) {
      this.errors = 'Especialidade não informada!';
    }

    this.getSpecialtyThemes(this.specialtyId);
    
    this.formData.specialty_id = this.specialtyId;
  },

  methods: {
    ...mapActions([
      'getSpecialtyThemesAction',
    ]),

    closeModal()  {
      this.$emit('closeModal');
    },

    async getSpecialtyThemes(specialtyId) {
      try {
        const response = await this.getSpecialtyThemesAction(specialtyId);
        this.tags = response.data;
      } catch(error) {
        this.errors = error;
      }
    },

    async storeTag()
    {
      try{
        await this.storeTagAction(this.formData);
        this.toast('Nova TAG criada com sucesso', 'success')
        this.getSpecialtyTags(this.specialtyId);
        this.formData.name = '';
      } catch(error) {
        this.errors = error;
      }
    },

    async updateTag(id) {

    },

    async deleteTag(id) {
      try {
        await this.deleteTagAction(id)
        this.toast('Deletou com sucesso', 'success')
        this.getSpecialtyTags(this.specialtyId);
      } catch(error) {
        this.errors = error;
      }
    },

    toast(text, type) {
      return createToast(
        {
          title: text,
          // description: 'teste'
        },
        {
          type: type,
          transition: 'zoom',
          timeout: 3000,
      })
    }
  },
}
</script>