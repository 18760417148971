import axios from 'axios';
import { URI_BASE, TOKEN } from '@/config/api';

const routes = {
  years: 'years/all',
  store: 'years/store',
  update: 'years/update',
  destroy: 'years/destroy',
  search: 'years/search',
};

export default {
  async getYearsAction({commit}) { 
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      return Promise.reject(401);
    }

    const years = await axios.get(`${URI_BASE}${routes.years}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    commit('YEARS', years.data);

    return years;
  },

  async storeYearAction({commit}, data) {    
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }

    return await axios.post(`${URI_BASE}${routes.store}`, data, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
  },

  async updateYearAction({commit}, params) {
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }

    return await axios.put(`${URI_BASE}${routes.update}/${params.id}`, params.data, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
  },

  async destroyYearAction({commit}, id) {
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }
  
    return await axios.delete(`${URI_BASE}${routes.destroy}/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
  },

  async searchYearsAction({commit}, params) { 
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }

    const years = await axios.get(`${URI_BASE}${routes.search}?name=${params.name}&institution_id=${params.institution_id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    commit('YEARS', years.data);

    return years;
  },
}