<template>
  <div class="i5-breadcrumb">
    <ul>
      <li>você está em:</li>
      <li>Dashboard</li>
    </ul>
  </div>
  <div class="build-content i5_animated i5_fadeInRight">
    <div class="build-datagrid-cards build-datagrid-cards-3">
      <ul class="list-column-md">
        
        <li>
          <router-link :to="{name: 'tests'}">
            <div class="title">Prova(s)</div>
            <div class="description">{{ testsLength }} Provas Cadastrada(s)</div>
          </router-link>
        </li>
        
        <li>
          <router-link :to="{name: 'institutions'}">
            <div class="title">Prova(s)/Instituição(ões)</div>
            <div class="description">{{ institutionsLength }} Provas/Instituições Cadastrada(s)</div>
          </router-link>
        </li>
        
        <li>
          <router-link :to="{name: 'medicineAreas'}">
            <div class="title">Área(s) da Medicina</div>
            <div class="description">{{ medicineAreasLength }} Áreas da Medicina Cadastrada(s)</div>
          </router-link>
        </li>
        
        <li>
          <router-link :to="{name: 'specialties'}">
            <div class="title">Especialidade(s)</div>
            <div class="description">{{ specialtiesLength }} Especialidades Cadastrada(s)</div>
          </router-link>
        </li>
        
        <li>
          <router-link :to="{name: 'themes'}">
            <div class="title">Tema(s)/Doença(s)</div>
            <div class="description">{{ themesLength }} Temas/Doenças Cadastrada(s)</div>
          </router-link>
        </li>
        
        <li>
          <router-link :to="{name: 'years'}">
            <div class="title">Ano(s) de Prova</div>
            <div class="description">{{ yearsLength }} Anos de Prova Cadastrada(s)</div>
          </router-link>
        </li>
        
      </ul>
    </div>
  </div>

  <Loader
    v-if="executeLoader == true"
  />

  <Toast
    v-if="executeToast"
    :text="textToast"
    :description="descriptionToast"
    :type="typeToast"
  />
</template>

<script>
import { URI_BASE, URI_ADMIN, TOKEN } from '@/config/api';
import { mapActions, mapState } from 'vuex';

export default {
    name: 'DashboardView',

    data() {
      return {
        questionsLength: 0,
        institutionsLength: 0,
        medicineAreasLength: 0,
        specialtiesLength: 0,
        yearsLength: 0,
        themesLength: 0,
        errors: null,
        executeLoader: false,
        testsLength: 0,
        executeToast: false,
        textToast: '',
        descriptionToast: '',
        typeToast: '',
      }
    },

    async mounted() {
      try {
        this.executeLoader = true;

        Promise.all([
          await this.getInstituions(),
          await this.getMedicineAreas(),
          await this.getYears(),
          await this.getThemes(),
          await this.getSpecialties(),
          await this.getTests(),
        ]).then((result) => {
          result.forEach(resp => {
            if (typeof resp == 'object' && resp.response.status == 401) {
              window.localStorage.removeItem(TOKEN);
              this.executeToast = true;
              this.textToast = 'É necessário fazer login novamente', 'danger';
              this.typeToast = 'danger'
              this.$router.push({name: 'home'})
            }
          })
        })     
      } catch(error) {
        console.log(error)
      } finally {
        this.executeLoader = false;        
      }
    },
    
    computed: {
      ...mapState({
      }),
    },

    methods: {
      ...mapActions([
        'getInstitutionsAction',
        'getThemesAction',
        'getMedicineAreasAction',
        'getYearsAction',
        'getSpecialtiesAction',
        'getTestsAction',
      ]),

      async getTests() {
        try {
          const response = await this.getTestsAction();
          return this.testsLength = response.data.length;
        } catch (error) {
        }
      },

      async getInstituions() {
        try {
          const institutions = await this.getInstitutionsAction();
          
          return this.institutionsLength = institutions.data.length;

        } catch(error) {
          return this.errors = error;
        }
      },

      async getMedicineAreas() {
        try {
          const medicineAreas = await this.getMedicineAreasAction();
          
          return this.medicineAreasLength = medicineAreas.data.length;

        } catch(error) {
          return this.errors = error;
        }
      },

      async getYears() {
        try {
          const years = await this.getYearsAction();
          
          return this.yearsLength = years.data.length;

      } catch(error) {
         return this.errors = error;
        }
      },

      async getThemes() {
        try {
          const themes = await this.getThemesAction();
          
          return this.themesLength = themes.data.length;

        } catch(error) {
          return this.errors = error;
        }
      },

      async getSpecialties() {
        try {
          const specialties = await this.getSpecialtiesAction();

          return this.specialtiesLength = specialties.data.length;

        } catch (error) {
          return this.errors = error;
        }
      }
    }
}
</script>