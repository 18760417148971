<template>
  <loader
    v-if="executeLoader"
  />
  <div class="i5-breadcrumb">
    <ul>
      <li>você está em:</li>
      <li>Anos</li>
    </ul>
  </div>

  <div style="margin-top:25px;">
    <years-filter
      @cleanFilter="cleanFilter"
      @showLoader="showLoader"
      @hideLoader="hideLoader"
    />
  </div>
  
  <years-list
    :key="filterCleaner"
    :getYearsAfterFilter="getYearsAfterFilter"
    @showLoader="showLoader"
    @hideLoader="hideLoader"
  />
</template>

<script>
import YearsList from '@/components/years/YearsList.vue';
import YearsFilter from '@/components/years/YearsFilter.vue';
import Loader from '@/components/loader/Loader.vue';

export default {
  name: "YearsView",

  data() {
    return {
      filterCleaner: 0,
      getYearsAfterFilter: false,
      executeLoader: false,
    }
  },

  components: {
    YearsList,
    YearsFilter,
    Loader,
  },
  
  mounted() {
    if (this.$route.query.hasOwnProperty('specialtyId')) {
    }
  },

  methods: {
    cleanFilter() {
      this.getYearsAfterFilter = true;
      this.filterCleaner++
    },

    showLoader() {
      return this.executeLoader = true;
    },

    hideLoader() {
      return this.executeLoader = false;
    }
  }
}
</script>