<template>
  <div class="i5-loading">
    <div class="i5-loading-loading">
      
      <div class="preloader-wrapper big active">
        <div class="spinner-layer">
          <div class="circle-clipper left"><div class="circle"></div></div>
          <div class="gap-patch"><div class="circle"></div></div>
          <div class="circle-clipper right"><div class="circle"></div></div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
}
</script>