<template>
  <label for="themes">Instituição: </label>
  <select
    id="themes"
    name="themes"
    v-model="formData.test_id"
    @change="sendTestId"
  >
    <option :value="test">Escolha...</option>
    <option :value="test.id" v-for="(test, key) in sortTests" :key="key">
      {{ `${test.institutionName} - ${test.yearName}` }}
    </option>
  </select>
</template>

<script> 
import { mapActions, mapState } from 'vuex';

export default {
  props: {
    recieveTestId: 0,
  },

  emits: {
    sendTestId: null
  },

  mounted() {
    if (Object.keys(this.testsState.tests).length == 0) {
      this.getTests();
    }

    this.fetchRecievedData();
  },

  data() {
    return {
      formData: {
        test_id: 0,
      },
      test: 0,
    }
  },

  computed: {
    ...mapState({
      testsState: state => state.tests,
    }),

    sortTests() {
      if(this.testsState.tests.length){
        return this.testsState.tests.sort((a,b)=>{
          if (a.institutionName < b.institutionName) {
            return -1;
          } else if (a.institutionName > b.institutionName) {
            return 1;
          } else {
            return a.yearName - b.yearName;
          }
        });
      }
      return [];
    },

  },
  
  methods: {
    ...mapActions([
      'getTestsAction',
    ]),

    async getTests() {
      try {
        const tests = await this.getTestsAction();

      } catch(error) {
        return this.errors = error;
      }
    },

    fetchRecievedData() {
      this.formData.test_id = this.recieveTestId;
    },

    sendTestId(){
      this.$emit('sendTestId', this.formData.test_id, this.selectKey);
    }

  },
}
</script>