<template>
  <loader 
    v-if="executeLoader"
  />
  <div class="i5-breadcrumb">
    <ul>
      <li>você está em:</li>
      <li>Usuários</li>
    </ul>
  </div>

  <users-filter
    @cleanFilter="cleanFilter"
    @fillFiltro="fillFiltro"
    @showLoader="showLoader"
    @hideLoader="hideLoader"
  />

  <users-list
    @closeModal="closeModal"
    @openModal="openModal"
    @showLoader="showLoader"
    @hideLoader="hideLoader"
  />

  <loader 
    v-if="executeLoader"
  />
</template>

<script>
import { mapActions } from 'vuex';
import UsersList from '@/components/users/UsersList.vue';
import UsersFilter from '@/components/users/UsersFilter.vue';
import Loader from '@/components/loader/Loader.vue';

export default {
  name: "UsersView",

  data() {
    return {
      filters: {
        name: '',
        active: '',
        email: '',
      },
      executeLoader: false,
    }
  },

  components: {
    Loader,
    UsersList,
    UsersFilter
  },

  methods: {
    ...mapActions([
      'getUsersAction',
      'searchUserAction',
    ]),

    async cleanFilter() {
      this.filters.name = '';
      this.filters.active = '';
      this.filtersemail = '';
      try{
        this.showLoader();
        return await this.getUsersAction();
      }catch(error){
        console.log(error)
        return this.toast('Houve um erro ao buscar os usuário', 'danger');
      }finally{ 
        this.hideLoader();
      }
    },

    fillFiltro(data) {
      this.filters = {...data};
    },

    openModal() {
      
    },

    async closeModal() {   
      await this.searchUserAction(this.filters);
    },  

    showLoader() {
      return this.executeLoader = true;
    },

    hideLoader() {
      return this.executeLoader = false;
    }

  }
}

</script>