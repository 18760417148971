<template>
  <div class="i5-modal i5-modal-ativo">
    <div class="i5-modal-wrap in">
      <div class="i5-modal-fechar" title="fechar" @click.prevent="closeModal"><i class="fas fa-times"></i></div>
      <div class="i5-modal-header">{{ year.id == null ? 'Novo ano' : 'Atualizar ano' }}</div>
      <div class="i5-modal-content">
        
        <div class="form-grid">
          <div class="input-12 required">
            <label for="year_id">Ano</label>
            <input type="text" name="year_name" id="year_id" v-model="formData.name" />
          </div>
        </div>

        <div class="form-grid">
          <div class="input-12 required">
            <select-institutions
              :recievedInstitutionId="year.institution_id"
              @sendInstitutionId="sendInstitutionId"
            />
          </div>
        </div>

      </div>
      
      <div class="i5-modal-controls">
        <div class="i5-buttons i5-buttons-right">
          <button @click.prevent="year.id == null ? storeYear() : updateYear(year.id)" class="button adicionar waves-effect">{{ year.id == null ? 'Salvar' : 'Atualizar' }}</button>
          <button @click.prevent="closeModal" class="button default cancelar waves-effect">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

import SelectInstitutions from '@/components/institutions/SelectInstitutions.vue';


export default {
  name: 'CreateUpdateYearsModal',

  props: {
    year: {
      id: null,
      name: '',
      institution_id: null
    },
  },

  emits: {
    closeModal: null,
  },

  components: {
    SelectInstitutions,
  },

  data() {
    return {
      formData: {
        id: null,
        name: '',
        institution_id: null,
      }
    }
  },

  mounted() {
    this.fetchYearData()
  },

  methods: {
    ...mapActions([
      'storeYearAction',
      'updateYearAction',
      'destroyYearAction',
    ]),

    fetchYearData() {
      this.formData.id = this.year.id;
      this.formData.name = this.year.name;
      this.formData.institution_id = this.year.institution_id;
    },

    async storeYear() {
      try {
        await this.storeYearAction(this.formData);
        this.toast('Ano cadastrado com sucesso.', 'success');
        return this.closeModal(true);
      } catch(error) {
        return this.toast('Houve um erro ao cadastrar o Ano.', 'danger');
      }
    },

    async updateYear(id) {
      try {
        await this.updateYearAction({id, data: this.formData});
        this.toast('Ano alterado com sucesso', 'success');
        return this.closeModal(true);
      } catch(error) {        
        this.toast('Houve um problema para alterar o ano', 'danger');
      }
    },

    closeModal(reload = null) {
      this.$emit('closeModal', reload)
    },

    sendInstitutionId(id) {
      return this.formData.institution_id = id;
    },

    toast(text, type, description = '') {
      return createToast(
        {
          title: text,
          description: description,
        },
        {
          type: type,
          transition: 'zoom',
          timeout: 3000,
      })
    }
  }
}
</script>