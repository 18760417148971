import axios from 'axios';
import { URI_BASE, TOKEN } from '@/config/api';

const routes = {
  themes: 'themes/all',
  store: 'themes/store',
  update: 'themes/update',
  destroy: 'themes/destroy',
  search: 'themes/search'
};

export default {
  async getThemesAction({commit}) { 
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }

    const themes = await axios.get(`${URI_BASE}${routes.themes}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    commit('THEMES', themes.data);

    return themes;
  },

  async storeThemeAction({commit}, data) {    
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }

    return await axios.post(`${URI_BASE}${routes.store}`, data, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
  },

  async updateThemeAction({commit}, params) {
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }

    return await axios.put(`${URI_BASE}${routes.update}/${params.id}`, params.data, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
  },

  async destroyThemeAction({commit}, id) {
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }
  
    return await axios.delete(`${URI_BASE}${routes.destroy}/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
  },

  async searchAction({commit}, params) { 
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }

    const themes = await axios.get(`${URI_BASE}${routes.search}?name=${params.name}&specialty_id=${params.specialty_id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    commit('THEMES', themes.data);

    return themes;
  },
}