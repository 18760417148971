export const URI_BASE = process.env.NODE_ENV == 'development' ? 'http://127.0.0.1:8000/api/' : 'https://t1xos6znii.execute-api.us-east-1.amazonaws.com/dev/api/';
export const URI_IMAGE = process.env.NODE_ENV == 'development' ? 'http://127.0.0.1:8000/storage/' : 'https://mundo-revalida-checklist-images.s3.amazonaws.com/storage/';
// export const URI_BASE = process.env.NODE_ENV == 'development' ? 'http://127.0.0.1:8000/api/' : 'https://api.mundorevalida.com.br/api/';
// export const URI_IMAGE = process.env.NODE_ENV == 'development' ? 'http://127.0.0.1:8000/storage/' : 'https://api.mundorevalida.com.br/storage/';

// export const URI_BASE = 'https://t1xos6znii.execute-api.us-east-1.amazonaws.com/dev/api/';
// export const URI_IMAGE ='https://mundo-revalida-checklist-images.s3.amazonaws.com/storage/';
//export const URI_BASE = 'http://127.0.0.1:8000/api/';
//export const URI_IMAGE = 'http://127.0.0.1:8000/storage/'
//export const URI_BASE = 'https://api.mundorevalida.com.br/api/'
//export const URI_IMAGE = 'https://api.mundorevalida.com.br/storage/'
export const TOKEN = 'mundo-revalida';