<template>
  <label for="themes">Temas: </label>
  <select
    id="themes"
    name="themes"
    v-model="formData.theme_id"
    @change="sendThemeId"
  >
    <option :value="theme">Escolha...</option>
    <option :value="theme.id" v-for="(theme, key) in recievedSpecialtyId == 0 || recievedSpecialtyId == undefined ? sortThemes : reducedThemesBySpecialty" :key="key">
      {{ theme.name }}
    </option>
  </select>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  props: {
    recievedThemeId: 0,
    recievedSpecialtyId: 0,
    filter: 0,
  },

  emits: {
    sendThemeId: null,
  },

  mounted() {
    if (Object.keys(this.themesState.themes).length == 0) {
      this.getThemes();
    }

    this.fetchRecievedData();

    if (this.recievedSpecialtyId != 0 && this.recievedSpecialtyId != undefined) {
      this.reduceThemesBySpecialty()
    }
  },

  data() {
    return {
      formData: {
        theme_id: 0,
      },
      theme: 0,
      reducedThemesBySpecialty: [],
    }
  },

  computed: {
    ...mapState({
      themesState: state => state.themes,
    }),

    sortThemes() {
      if(this.themesState.themes.length){
        return this.themesState.themes.sort((a,b)=>{
          if (a.name < b.name) {
            return -1;
          } else {
            return 1;
          } 
        });
      }
      return [];
    },
  },
  
  methods: {
    ...mapActions([
      'getThemesAction',
    ]),

    fetchRecievedData() {
      this.formData.theme_id = this.recievedThemeId;
    },

    reduceThemesBySpecialty() {
      this.themesState.themes.map(theme => {
        if (theme.specialty_id == this.recievedSpecialtyId) {
          this.reducedThemesBySpecialty.push(theme);
        }
      })
    },

    sendThemeId() {
      this.$emit('sendThemeId', this.formData.theme_id);
    },

    async getThemes() {
      try {
        return await this.getThemesAction();

      } catch(error) {
        return this.errors = error;
      }
    },
  },
}
</script>