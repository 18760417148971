import axios from 'axios';
import { URI_BASE, TOKEN } from '@/config/api';

const routes = {
  institutions: 'institutions/all',
  store: 'institutions/store',
  update: 'institutions/update',
  destroy: 'institutions/destroy',
  search: 'institutions/search',
  yearsInstitutions: 'institutions/get-institutions-by-year'
};

export default {
  async getInstitutionsAction({commit}) {
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject(401);
    }

    const institutions = await axios.get(`${URI_BASE}${routes.institutions}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    commit('INSTITUTIONS', institutions.data);

    return institutions;
  },

  async storeInstitutionAction({commit}, data) {    
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }

    return await axios.post(`${URI_BASE}${routes.store}`, data, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
  },

  async updateInstitutionAction({commit}, params) {
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }

    return await axios.put(`${URI_BASE}${routes.update}/${params.id}`, params.data, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
  },

  async destroyInstitutionAction({commit}, id) {
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }
  
    return await axios.delete(`${URI_BASE}${routes.destroy}/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
  },

  async searchInstitutionsAction({commit}, params) { 
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }

    const institutions = await axios.get(`${URI_BASE}${routes.search}?name=${params.name}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    commit('INSTITUTIONS', institutions.data);

    return institutions;
  },

  async getInstitutionsByYearAction({commit}, yearId) { 
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }

    return await axios.get(`${URI_BASE}${routes.search}/yearId=${yearId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
  },
}