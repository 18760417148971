<template>
  <div class="i5-breadcrumb">
    <ul>
      <li>você está em:</li>
      <li>Instituições</li>
    </ul>
  </div>

  <div style="margin-top:25px;">
    <institutions-filter
      @cleanFilter="cleanFilter"
      @showLoader="showLoader"
      @hideLoader="hideLoader"
    />
  </div>
  
  <institutions-list
    :key="filterCleaner"
    :getInstitutionsAfterFilter="getInstitutionsAfterFilter"
    @showLoader="showLoader"
    @hideLoader="hideLoader"
  />

  <loader 
    v-if="executeLoader"
  />
</template>
<script>
import InstitutionsList from '@/components/institutions/InstitutionsList.vue';
import InstitutionsFilter from '@/components/institutions/InstitutionsFilter.vue';
import Loader from '@/components/loader/Loader.vue';

export default {
  name: "InstitutionsView",

  components: {
    InstitutionsList,
    InstitutionsFilter,
    Loader,
  },

  data() {
    return {
      filterCleaner: 0,
      getInstitutionsAfterFilter: false,
      executeLoader: false,
    }
  },

  methods: {
    cleanFilter() {
      this.getInstitutionsAfterFilter = true;
      this.filterCleaner++
    },

    showLoader() {
      return this.executeLoader = true;
    },

    hideLoader() {
      return this.executeLoader = false;
    }
  }
}
</script>