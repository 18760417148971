<template>
  <Login />
</template>

<script>
import Login from '@/components/login/Login.vue';

export default {
  name: 'HomeView',
  
  components: {
    Login
  }
}
</script>
