<template>
  <div class="i5-wrapper-login valign-wrapper" v-if="currentRouteName === 'home'">
    <div class="container">
      <div class="row">
        <div class="col s12 l4"></div>
  
        <div class="col s12 l4">
          <div class="logotipo">
            <img src="@/assets/images/logotipo.png" border="0" />
          </div>
  
          <router-view />
        </div>
  
        <div class="col s12 l4"></div>
      </div>
    </div>
  </div>

  <div id="i5-wrapper" v-else>
    <div id="i5-left" class="navbar-off-canvas">
      <div class="i5-content">
        <div @click="toggleNavbar" class="i5-navbar-click"><span></span></div>
        <div class="i5-usuario">
          <img src="@/assets/images/logotipo_ic.png" border="0" /> 
          administrador
          <div @click="closeNavbar" class="close-navbar">
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
            </svg>
          </div>
        </div>
        <div class="i5-navbar">
          <ul>
            <li><router-link @click="closeNavbar" :to="{name: 'dashboard'}">Dashboard</router-link></li>
            <li><router-link @click="closeNavbar" :to="{name: 'tests'}">Provas</router-link></li>
            <!-- <li><router-link :to="{name: 'questions'}">Questões</router-link></li> -->
            <li><router-link @click="closeNavbar" :to="{name: 'institutions'}">Instituições</router-link></li>
            <li><router-link @click="closeNavbar" :to="{name: 'medicineAreas'}">Áreas da Medicina</router-link></li>
            <li><router-link @click="closeNavbar" :to="{name: 'specialties'}">Especialidades</router-link></li>
            <li><router-link @click="closeNavbar" :to="{name: 'themes'}">Temas/Doenças</router-link></li>
            <li><router-link @click="closeNavbar" :to="{name: 'years'}">Anos de Prova</router-link></li>
            <li><router-link @click="closeNavbar" :to="{name: 'simulados'}">Simulados</router-link></li>
            <li><router-link @click="closeNavbar" :to="{name: 'usuarios'}">Usuários</router-link></li>
          </ul>
        </div>
      </div>
    </div>

    <div id="i5-right">
      <div class="i5-content">

        <div class="i5-header">
          <div class="i5-dominio">Mundo Revalida</div>
          <div class="form-grid">
            <div class="input-12">
              <div class="i5-buttons i5-buttons-right">
                <button class="button confirmar waves-effect" @click.prevent="logout">Sair</button>
              </div>
            </div>
          </div>
          
        </div>

        <router-view />
      </div>

      <div class="i5-information">
        <ul>
          <li>&copy; 2022 mundorevalida.com.br</li>
        </ul>
      </div>
    </div>
  </div>
  <loader
    v-if="executeLoader == true"
  />
  <Toast
    v-if="executeToast"
  />
</template>

<script>
  import { TOKEN, URI_BASE } from '@/config/api'
  import {mapActions, mapState} from 'vuex';
  import Loader from '@/components/loader/Loader.vue';

  export default {
    computed: {
      currentRouteName() {
        return this.$route.name;
      },

      ...mapState({
        executeToast: state => state.executeToast,
      })
    },

    components: {
      Loader,
    },

    data() {
      return {
        executeLoader: false,
      }
    },

    methods: {
      ...mapActions([
        'logoutAction',
      ]),

      toggleNavbar(){
        const menu = document.querySelector(".navbar-off-canvas");
        menu.classList.toggle('active');   
      },

      closeNavbar(){
        const menu = document.querySelector(".navbar-off-canvas");
        menu.classList.remove('active');  
      },

      async logout() {
        try {
          this.executeLoader = true
          await this.logoutAction({ id: window.localStorage.getItem('id') });
          window.localStorage.removeItem(TOKEN)
          window.localStorage.removeItem('name');
          window.localStorage.removeItem('email');
          this.$router.push({name: 'home'})
        } catch (error) {
          console.error(error);
        } finally {
          return this.executeLoader = false;
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "assets/scss/base/variables";
  @import "assets/scss/base/mixins";
  @import "assets/scss/base/resets";

  @import "assets/scss/external/animate";
  @import "assets/scss/external/fontawesome";
  @import "assets/scss/external/materialize";
  @import "assets/scss/external/swiper";

  @import 'assets/scss/components/structure';
  @import 'assets/scss/components/general';
  @import 'assets/scss/components/boxes';
  @import 'assets/scss/components/breadcrumbs';
  @import 'assets/scss/components/contents';
  @import 'assets/scss/components/controls';
  @import 'assets/scss/components/drawer';
  @import 'assets/scss/components/forms';
  @import 'assets/scss/components/grids';
  @import 'assets/scss/components/loading';
  @import 'assets/scss/components/external';
  @import 'assets/scss/components/modal';
  @import 'assets/scss/components/navbar';
  @import 'assets/scss/components/pagination';
  @import 'assets/scss/components/sandbox';
  @import 'assets/scss/components/views';

  @import 'assets/scss/pages/login';
</style>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
