<template>
  <loader v-if="executeLoader" />
  <div v-if="questionsState.questions == undefined || questionsState.questions.length == 0">
    Nenhuma questão encontrada
  </div>

  <div class="build-content i5_animated i5_fadeInRight">
    <div class="build-datagrid-default">
      <ul>
        <li v-for="(question, key) in questionsState.questions" :key="key">
          <div>
            <div class="info">
              <div>
                <span>ID:</span>
                <span>{{ question.id }}</span>
              </div>

              <div>
                <span>Questão:</span>
                <span>{{ question.question }}</span>
              </div>
              <div v-if="question.is_discursive == 0">
                <span>Resposta Correta:</span>
                <span>{{ question.alternative_name }}</span>
              </div>
              <div>
                <span>Instituição:</span>
                <span>{{ question.institution_name }}</span>
              </div>
              <div>
                <span>Ano:</span>
                <span>{{ question.year_name }}</span>
              </div>
              <div>
                <span>Área(s) da Medicina:</span>
                <span>{{ medicineAreasNames(question.id) }}</span>
              </div>
              <div>
                <span>Especialidade(s):</span>
                <span>{{ specialtiesNames(question.id) }}</span>
              </div>
              <div>
                <span>Tema(s):</span>
                <span>{{ themesNames(question.id) }}</span>
              </div>
            </div>
    
            <div class="i5-controls-default">
              <button 
                v-if="this.questionsIdsSelected.includes(question.id)"
                @click.prevent="removeQuestionToMockTest(question.id)"
                title="Editar" 
                class="editar waves-effect" 
                style="background-color: #dc3545; color: #fff; padding: .5rem 1rem;"
              >
                Cancelar
              </button>
              <button 
                v-else
                @click.prevent="addQuestionToMockTest(question.id)" 
                title="Editar" 
                class="editar waves-effect" 
                style="background-color: #198754; color: #fff; padding: .5rem 1rem;"
              >
                Adicionar ao simulado
              </button>
            </div>
          </div>
        </li>

      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default {
  name: 'AddQuestionsForMockTests',

  props: {
    mockTestId: 0,
    executeLoader: false,
  },

  emits: {
    showLoader: null,
    hideLoader: null
  },

  data() {
    return {
      filters: {
        institution_id: 0,
        medicine_area_id: 0,
        specialty_id: 0,
        theme_id: 0,
        year_id: 0,
        test_id: 0,
        question: '',
        mock_test_id: 0,
        perPage: 150
      },
      questionsIdsSelected: [],
    }
  },

  components: {
  },

  mounted() {
    this.getQuestions();
  },

  computed: {

    ...mapState({
      questionsState: state => state.questions,
    }),

    medicineAreasNames() {
      return questionId => {
        let medicineAreasNames = ``;
        this.questionsState.questions.map(question => {
          if(question.medicine_areas) Object.values(question.medicine_areas).map(area => {
            if (question.id == questionId) {
              medicineAreasNames == '' ? medicineAreasNames += `${area.name}` : medicineAreasNames +=`, ${area.name}`
            }
          })
        })
        return medicineAreasNames;
      }
    },

    specialtiesNames() {
      return questionId => {
        let specialtiesNames = ``;
        this.questionsState.questions.map(question => {
          if(question.specialties) Object.values(question.specialties).map(specialty => {
            if (question.id == questionId) {
              specialtiesNames == '' ? specialtiesNames += `${specialty.name}` : specialtiesNames +=`, ${specialty.name}`
            }
          })
        })
        return specialtiesNames;
      }
    },

    themesNames() {
      return questionId => {
        let themesNames = ``;
        this.questionsState.questions.map(question => {
          if(question.themes) Object.values(question.themes).map(theme => {
            if (question.id == questionId) {
              themesNames == '' ? themesNames += `${theme.name}` : themesNames +=`, ${theme.name}`
            }
          })
        })
        return themesNames;
      }
    }
  },

  methods: {
    ...mapActions([
      'searchQuestionsForMockTestAction',
      'storeQuestionForMockTestAction',
      'destroyQuestionForMockTestAction'
    ]),

    async getQuestions() {
      try {
        this.showLoaderEmiter();
        this.filters.mock_test_id = Number(this.$route.params.id);
        return await this.searchQuestionsForMockTestAction(this.filters);
      } catch(error) {
        console.log(error);
        this.toast('Houve um erro ao buscar as questões', 'danger');
      } finally {
        this.hideLoaderEmiter();
      }
    },

    async addQuestionToMockTest(questionId){
      try {
        this.showLoaderEmiter();
        const response = await this.storeQuestionForMockTestAction({
          question_id: questionId,
          mock_test_id: this.mockTestId,
        });
        if(response.status === 201){
          this.toast('Questão salva com sucesso', 'success');
          this.questionsIdsSelected.push(questionId);
        }
      } catch(error) {
        console.log(error);
        this.toast('Houve um erro ao salvar a questão', 'danger');
      } finally {
        this.hideLoaderEmiter();
      }
    },

    async removeQuestionToMockTest(questionId){
      try {
        this.showLoaderEmiter();
        const response = await this.destroyQuestionForMockTestAction({
          question_id: questionId,
          mock_test_id: this.mockTestId
        });
        if(response.status === 200){
          this.toast('Questão cancelada com sucesso', 'success');
          const questionIdIndex = this.questionsIdsSelected.indexOf(questionId);
          if (questionIdIndex > -1) {
            this.questionsIdsSelected.splice(questionIdIndex, 1);
          }
        }
      } catch(error) {
        console.log(error);
        this.toast('Houve um erro ao cancelar a questão', 'danger');
      } finally {
        this.hideLoaderEmiter();
      }
    },

    showLoaderEmiter() {
      return this.$emit('showLoader')
    },

    hideLoaderEmiter() {
      return this.$emit('hideLoader');
    },

    toast(text, type, description = '') {
      return createToast(
        {
          title: text,
          description: description,
        },
        {
          type: type,
          transition: 'zoom',
          timeout: 3000,
      })
    }
  }
}
</script>