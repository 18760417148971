import axios from 'axios';
import { URI_BASE, TOKEN } from '@/config/api';

const routes = {
  questionsByTestId: 'questions/by-test-id',
  questions: 'questions/all',  
  store: 'questions/store',
  update: 'questions/update',
  destroy: 'questions/destroy',
  search: 'questions/search',
};

export default {
  async getQuestionsByTestIdAction({commit}, testId) {
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      return Promise.reject(401);
    }

    const questions = await axios.get(`${URI_BASE}${routes.questionsByTestId}/${testId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
    
    commit('QUESTIONS', questions.data);

    return questions;
  },

  async getQuestionsAction({commit}) { 
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }

    const questions = await axios.get(`${URI_BASE}${routes.questions}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    commit('QUESTIONS', questions.data);

    return questions;
  },

  async storeQuestionAction({commit}, data) {    
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }

    return await axios.post(`${URI_BASE}${routes.store}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      }
    });
  },

  async updateQuestionAction({commit}, params) {
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }

    return await axios.post(`${URI_BASE}${routes.update}/${params.id}`, params.data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      }
    });
  },

  async destroyQuestionAction({commit}, id) {
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }
  
    return await axios.delete(`${URI_BASE}${routes.destroy}/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
  },

  async searchQuestionsAction({commit}, params) { 
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }

    const questions = await axios.get(`${URI_BASE}${routes.search}?
      question=${params.question}&
      theme_id=${params.theme_id}&
      institution_id=${params.institution_id}&
      specialty_id=${params.specialty_id}&
      year_id=${params.year_id}&
      medicine_area_id=${params.medicine_area_id}
      `
      , {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    commit('QUESTIONS', questions.data);

    return questions;
  },
}