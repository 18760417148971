<template>
	<div>
		
	</div>
</template>
<script>
import { mapState } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default {
	computed: {
		...mapState({
			executeToast: state => state.executeToast,
			text: state => state.text,
			description: state => state.description,
			type: state => state.type,
		})
	},

	mounted() {
		if (this.executeToast == false) {
			return false;
		}

		this.toaster();
		this.$store.commit('destroyToast', false);
	},

	methods: {
		toaster() {
			createToast(
				{
					title: this.text,
					description: this.description || '',
				},
				{
					type: this.type,
					transition: 'zoom',
					timeout: 3000,
				})
		}		
	},
	
}
</script>