import axios from 'axios';
import { URI_BASE, TOKEN } from '@/config/api';

const routes = {
  tests: 'tests/all',
  test: 'tests/get-by-id',
  store: 'tests/store',
  update: 'tests/update',
  destroy: 'tests/destroy',
  search: 'tests/search',
  searchQuestions: 'tests/search-questions'
};

export default {
  async getTestsAction({commit}) { 
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      return Promise.reject({response: {status: 401}});
    }

    const tests = await axios.get(`${URI_BASE}${routes.tests}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    commit('TESTS', tests.data);

    return tests;
  },

  async getTestAction({commit}, testId) {
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      return Promise.reject(401);
    }

    return await axios.get(`${URI_BASE}${routes.test}/${testId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
  },

  async storeTestAction({commit}, data) {    
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      return Promise.reject(401);
    }

    return await axios.post(`${URI_BASE}${routes.store}`, data, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
  },

  async updateTestAction({commit}, params) {
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }

    return await axios.put(`${URI_BASE}${routes.update}/${params.id}`, params.data, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
  },

  async destroyTestAction({commit}, id) {
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }
  
    return await axios.delete(`${URI_BASE}${routes.destroy}/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
  },

  async searchTestsAction({commit}, params) { 
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }

    const tests = await axios.get(`${URI_BASE}${routes.search}?name=${params.name}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    commit('TESTS', tests.data);

    return tests;
  },

  async searchQuestionsForTestAction({commit}, params) {
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }
    
    const tests = await axios.get(`${URI_BASE}${routes.searchQuestions}?
      question=${params.question}&
      theme_id=${params.theme_id}&
      institution_id=${params.institution_id}&
      specialty_id=${params.specialty_id}&
      year_id=${params.year_id}&
      medicine_area_id=${params.medicine_area_id}&
      test_id=${params.test_id}
      `
      , {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    commit('QUESTIONS', tests.data);

    return tests;
  },
}