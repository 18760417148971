import { createRouter, createWebHistory } from 'vue-router'
import { TOKEN } from '@/config/api';

import HomeView from '../views/HomeView.vue'
import DashboardView from '@/views/DashboardView.vue';
import QuestionsView from '@/views/QuestionsView.vue';
import InstitutionsView from '@/views/InstitutionsView.vue';
import MedicineAreasView from '@/views/MedicineAreasView.vue';
import ThemesView from '@/views/ThemesView.vue';
import SpecialtiesView from '@/views/SpecialtiesView.vue';
import YearsView from '@/views/YearsView.vue';
import TestsView from '@/views/TestsView.vue';
import UsersView from '@/views/UsersView';
import MockTestsView from '@/views/MockTestsView';
import MockTestQuestionsView from '@/views/MockTestsQuestionsView';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },


  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/provas',
    name: 'tests',
    component: TestsView
  },
  {
    path: '/questoes',
    name: 'questions',
    component: QuestionsView
  },
  {
    path: '/instituicoes',
    name: 'institutions',
    component: InstitutionsView
  },
  {
    path: '/areas-da-medicina',
    name: 'medicineAreas',
    component: MedicineAreasView
  },
  {
    path: '/especialidades',
    name: 'specialties',
    component: SpecialtiesView
  },
  {
    path: '/temas',
    name: 'themes',
    component: ThemesView
  },
  {
    path: '/anos',
    name: 'years',
    component: YearsView
  },
  {
    path: '/prova/:id',
    name: 'test',
    component: () => import('@/views/TestView.vue'),
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: UsersView
  },
  {
    path: '/simulados',
    name: 'simulados',
    component: MockTestsView
  },
  {
    path: '/simulado/:id',
    name: 'simulado',
    component:  () => import('@/views/MockTestView.vue'), 
  },
  {
    path: '/simulado/:id/adicionar-questoes',
    name: 'simulado-adicionar-questoes',
    component:  () => import('@/views/MockTestsQuestionsView.vue'), 
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
}) 

router.beforeEach((to) => {
  if (to.fullPath.includes('/_admin')) {
    to.fullPath = to.fullPath.replace('/_admin', '')
    to.href = to.href.replace('/_admin', '')
    return to.fullPath;
  }

  if (!window.localStorage.getItem(TOKEN) && to.name != 'home') {
    return {name: 'home'};
  }

  if (window.localStorage.getItem(TOKEN) && to.name == 'home') {
    return {name: 'dashboard'}
  }
})

export default router
