<template>
  <div class="i5-modal i5-modal-ativo">
    <div class="i5-modal-wrap in">
      <div class="i5-modal-fechar" title="fechar" @click.prevent="closeModal"><i class="fas fa-times"></i></div>
      <div class="i5-modal-header">Adicionar novo tema</div>
      <div class="i5-modal-content">
        
        <div class="form-grid">
          <div class="input-12 required">
            <label for="theme_id">Título</label>
            <input type="text" name="theme_name" id="theme_id" v-model="formData.name" />
          </div>
        </div>

        <div class="form-grid">
          <div class="input-12 required">
            <select-specialties
              :recievedSpecialtyId="theme.specialty_id"
              @sendSpecialtyId="sendSpecialtyId"
            />
          </div>
        </div>

      </div>
      
      <div class="i5-modal-controls">
        <div class="i5-buttons i5-buttons-right">
          <button @click.prevent="theme.specialty_id == null ? createTheme() : updateTheme(theme.id)" class="button adicionar waves-effect">{{ theme.specialty_id == null ? 'Salvar' : 'Atualizar' }}</button>
          <button @click.prevent="closeModal" class="button default cancelar waves-effect">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

import SelectSpecialties from '@/components/specialties/SelectSpecialties.vue';


export default {
  name: 'CreateUpdateThemesModal',

  props: {
    theme: {
      name: '',
      specialty_id: null
    },
  },

  emits: {
    closeModal: null,
  },

  components: {
    SelectSpecialties,
  },

  data() {
    return {
      formData: {
        id: null,
        name: '',
        specialty_id: null,
      }
    }
  },

  mounted() {
    this.fetchThemeData()
  },

  methods: {
    ...mapActions([
      'storeThemeAction',
      'updateThemeAction',
      'destroyThemeAction',
    ]),

    fetchThemeData() {
      this.formData.id = this.theme.id;
      this.formData.name = this.theme.name;
      this.formData.specialty_id = this.theme.specialty_id;
    },

    async createTheme() {
      try {
        await this.storeThemeAction(this.formData);
        this.toast('Tema cadastrado com sucesso.', 'success');
        return this.closeModal(true);
      } catch(error) {
        return this.toast('Houve um erro ao cadastrar o tema.', 'danger');
      }
    },

    async updateTheme(id) {
      try {
        await this.updateThemeAction({id, data: this.formData});
        this.toast('Tema alterado com sucesso', 'success');
        return this.closeModal(true);
      } catch(error) {        
        this.toast('Houve um problema para alterar o tema', 'danger');
      }
    },

    async destroyTheme(id) {
      try {
        await this.destroyThemeAction(id);
      } catch(error) {
        this.toast('Houve um problema para excluír o tema', 'danger');
      }
    },

    closeModal(reload = null) {
      this.$emit('closeModal', reload)
    },

    sendSpecialtyId(id) {
      return this.formData.specialty_id = id;
    },

    toast(text, type, description = '') {
      return createToast(
        {
          title: text,
          description: description,
        },
        {
          type: type,
          transition: 'zoom',
          timeout: 3000,
      })
    }
  }
}
</script>