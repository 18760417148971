<template>
    <div>
        <div class="i5-modal i5-modal-ativo">
            <div class="i5-modal-wrap in">
                <div class="i5-modal-fechar" title="fechar" @click.prevent="closeModal"><i class="fas fa-times"></i></div>
                <div class="i5-modal-header">{{ mockTest.id ? `Atualizar simulado - ${mockTest.name}` : 'Adicionar novo simulado' }}</div>
                <div class="i5-modal-content">
                    
                    <div class="form-grid">
                        <div class="input-12 required">
                            <label for="name">Nome</label>
                            <input type="text" name="mack_test_name" id="name" v-model="formData.name"/>
                        </div>
                    </div>

                    <div class="form-grid">
                        <div class="input-12">
                            <label for="description">Descrição</label>
                            <textarea name="mack_test_description" id="description" v-model="formData.description"></textarea>
                        </div>
                    </div>
    
                    <div class="form-grid">
                        <div class="input-12">
                            <label for="time">Duração (em minutos)</label>
                            <input type="number" name="mack_test_time" id="time" v-model="formData.time"/>
                        </div>
                    </div>
    
                    <div class="form-grid">
                        <div class="input-12 required">
                            <label for="start-date">Data de início</label>
                            <input type="date" name="mack_test_date_start" id="start-date" v-model="formData.start_date"/>
                        </div>
                    </div>
    
                    <div class="form-grid">
                        <div class="input-12">
                            <label for="end-date">Data de término</label>
                            <input type="date" name="mack_test_date_end" id="end-date" v-model="formData.end_date"/>
                        </div>
                    </div>
    
                </div>
                
                <div class="i5-modal-controls">
                    <div class="i5-buttons i5-buttons-right">
                    <button @click.prevent="mockTest.id == null ? createMockTest() : updateMockTest(mockTest.id)" class="button adicionar waves-effect">{{ mockTest.id == null ? 'Salvar' : 'Atualizar' }}</button>
                    <button @click.prevent="closeModal" class="button default cancelar waves-effect">Cancelar</button>
                    </div>
                </div>
            </div>
        </div>
        <loader
            v-if="executeLoader"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import Loader from '@/components/loader/Loader.vue';

export default {
    name: 'CreateUpdateMockTestsModal',

    props: {
        mockTest: {
            id: null,
            name: null,
            description: null,
            time: null,
            start_date: null,
            end_date: null,
        }
    },

    components: {
        Loader,
    },

    data() {
        return{
            formData: {
                id: null,
                name: null,
                description: null,
                time: null,
                start_date: null,
                end_date: null,
            },
            executeLoader: false,
        }
    },

    mounted() {
        this.fetchMockTestsData()
    },

    methods: {

        ...mapActions([
            'storeMockTestAction',
            'updateMockTestAction'
        ]),

        fetchMockTestsData(){
            this.formData.id = this.mockTest.id;
            this.formData.name = this.mockTest.name;
            this.formData.description = this.mockTest.description;
            this.formData.time = this.mockTest.time;
            this.formData.start_date = this.mockTest.start_date;
            this.formData.end_date = this.mockTest.end_date;
        },

        async createMockTest(){
            if(!this.formData.name || !this.formData.start_date){
                return alert("Preencha todos os campos obrigatórios")
            }
            try{    
                this.executeLoader = true;
                await this.storeMockTestAction(this.formData);
                this.toast('Simulado cadastrado com sucesso.', 'success');
                return this.closeModal(true);
            }catch(error){
                //this.closeModal(true);
                console.log(error);
                const message = error.response.data.message?? 'Houve um erro ao cadastrar o usuário.';
                return this.toast(message, 'danger');
            }finally{
                this.executeLoader = false;
            }
        },

        async updateMockTest(mockTestId){
            if(!this.formData.name || !this.formData.start_date){
                return alert("Preencha todos os campos obrigatórios")
            }
            try{    
                this.executeLoader = true;
                await this.updateMockTestAction({data: this.formData, id: mockTestId});
                this.toast('Simulado alterado com sucesso.', 'success');
                return this.closeModal(true);
            }catch(error){
                //this.closeModal(true);
                console.log(error);
                const message = error.response.data.message?? 'Houve um erro ao cadastrar o usuário.';
                return this.toast(message, 'danger');
            }finally{
                this.executeLoader = false;
            }
        },

        closeModal(reload = null) {
            this.$emit('closeModal', reload)
        },

        toast(text, type, description = '') {
            return createToast(
                {
                    title: text,
                    description: description,
                },
                {
                    type: type,
                    transition: 'zoom',
                    timeout: 3000,
            })
        }
    }

}
</script>