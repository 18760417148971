<template>
  <form action="">
    <div class="form-grid">
      <div class="input-12">
        <div class="input-custom">
          <span style="font-size: 18px; font-weight: bold; display: block"
            >Buscar questão:</span
          >
        </div>
      </div>
    </div>

    <div class="form-grid">
      <div class="input-6">
        <select-institution
          :filter="filters.institution_id"
          @sendInstitutionId="sendInstitutionId"
          :key="cleanFilter"
        />
      </div>

      <div class="input-6">
        <select-medicine-area
          :filter="filters.medicine_area_id"
          @sendMedicineAreaId="sendMedicineAreaId"
          :key="cleanFilter"
        />
      </div>
    </div>

    <div class="form-grid">
      <div class="input-6">
        <select-specialties
          :filter="filters.specialty_id"
          @sendSpecialtyId="sendSpecialtyId"
          :key="cleanFilter || reduceSpecialties"
          :recievedMedicineAreaId="filters.medicine_area_id"
        />
      </div>

      <div class="input-6">
        <select-years
          :filter="filters.year_id"
          @sendYearId="sendYearId"
          :key="cleanFilter"
        />
      </div>
    </div>

    <div class="form-grid">
      <div class="input-6">
        <label for="">Trecho da questão</label>
        <input type="text" name="questionSlice" v-model="filters.question" />
      </div>
      <div class="input-6">
        <select-themes
          :filter="filters.theme_id"
          @sendThemeId="sendThemeId"
          :key="cleanFilter"
        />
      </div>
    </div>
  </form>

  <div class="form-grid" style="padding-top: 25px">
    <div class="input-12">
      <div class="i5-buttons i5-buttons-right">
        <button
          :disabled="validations == false"
          @click.prevent="search"
          class="button confirmar waves-effect"
        >
          Filtrar
        </button>
        <button
          @click.prevent="cleanSearch"
          class="button default waves-effect"
        >
          Limpar filtros
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

import SelectInstitution from "@/components/institutions/SelectInstitutions.vue";
import SelectMedicineArea from "@/components/medicineAreas/SelectMedicineAreas.vue";
import SelectThemes from "@/components/themes/SelectThemes.vue";
import SelectYears from "@/components/years/SelectYears.vue";
import SelectSpecialties from "../specialties/SelectSpecialties.vue";

export default {
  name: "QuestionsFilter",

  props: {
    filter: {
      theme_id: 0,
      institution_id: 0,
      specialty_id: 0,
      year_id: 0,
      medicine_area_id: 0,
    },
  },

  emits: {
    cleanFilter: null,
    showLoader: null,
    hideLoader: null,
  },

  components: {
    SelectInstitution,
    SelectMedicineArea,
    SelectThemes,
    SelectYears,
    SelectSpecialties,
  },

  data() {
    return {
      filters: {
        institution_id: 0,
        medicine_area_id: 0,
        specialty_id: 0,
        theme_id: 0,
        year_id: 0,
        question: '',
      },
      cleanFilter: 0,
      validations: false,
      reduceSpecialties: 0,
    };
  },

  watch: {
    filters: {
      handler: function (param) {
        if (param.question != '') {
          return (this.validations = true);
        }

        if (param.theme_id != 0) {
          return (this.validations = true);
        }

        if (param.institution_id != 0) {
          return (this.validations = true);
        }

        if (param.specialty_id != 0) {
          return (this.validations = true);
        }

        if (param.year_id != 0) {
          return (this.validations = true);
        }

        if (param.medicine_area_id != 0) {
          return (this.validations = true);
        }

        this.validations = false;
      },
      deep: true,
    },
  },

  mounted() {},

  methods: {
    ...mapActions(["searchQuestionsAction"]),

    async search() {
      try {
        this.showLoaderEmiter();
        const response = await this.searchQuestionsAction(this.filters);

        if (response.data.length == 0) {
          this.toast("Não encontramos questões com os filtros aplicados", "warning");
          return this.cleanSearch();
        }
      } catch (error) {
        return this.toast("Houve um erro ao buscar as questões", "danger");
      } finally {
        this.hideLoaderEmiter();
      }
    },

    cleanSearch() {
      this.cleanFilter++;
      this.filters.question = '';
      this.filters.theme_id = 0;
      this.filters.institution_id = 0;
      this.filters.specialty_id = 0;
      this.filters.year_id = 0;
      this.filters.medicine_area_id = 0;
      this.validations = false;
      return this.$emit("cleanFilter");
    },

    showLoaderEmiter() {
      return this.$emit("showLoader");
    },

    hideLoaderEmiter() {
      return this.$emit("hideLoader");
    },

    sendInstitutionId(institutionId) {
      this.filters.institution_id = institutionId;
    },

    sendMedicineAreaId(medicineAreaId) {
      this.reduceSpecialties++;
      this.filters.medicine_area_id = medicineAreaId;
    },

    sendSpecialtyId(specialtyId) {
      this.filters.specialty_id = specialtyId;
    },

    sendYearId(yearId) {
      this.filters.year_id = yearId;
    },

    sendThemeId(themeId) {
      this.filters.theme_id = themeId;
    },

    toast(text, type, description = "") {
      return createToast(
        {
          title: text,
          description: description,
        },
        {
          type: type,
          transition: "zoom",
          timeout: 3000,
        }
      );
    },
  },
};
</script>