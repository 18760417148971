<template>
  <div class="i5-breadcrumb">
    <ul>
      <li>você está em:</li>
      <li>Provas</li>
    </ul>
  </div>
  
  <tests-filter
    @cleanFilter="cleanFilter"
    @showLoader="showLoader"
    @hideLoader="hideLoader"
  />

  <tests-list
    :key="filterCleaner"
    :getTestsAfterFilter="getTestsAfterFilter"
    @showLoader="showLoader"
    @hideLoader="hideLoader"
  />

  <loader
    v-if="executeLoader"
  />
</template>

<script>
import Loader from '@/components/loader/Loader.vue';
import TestsList from '@/components/tests/TestsList.vue';
import TestsFilter from '@/components/tests/TestsFilter.vue';

export default {
  name: "TestsView",

  data() {
    return {
      filterCleaner: 0,
      getTestsAfterFilter: false,
      executeLoader: false,
    }
  },

  components: {
    TestsList,
    TestsFilter,
    Loader,
  },
  
  mounted() {
  },

  methods: {
    cleanFilter() {
      this.getTestsAfterFilter = true;
      this.filterCleaner++
    },

    showLoader() {
      return this.executeLoader = true;
    },

    hideLoader() {
      return this.executeLoader = false;
    }
  }
}
</script>