import axios from 'axios';
import { URI_BASE, TOKEN } from '@/config/api';

const routes = {
  getByQuestionId: 'alternatives/get-by-question-id',
}

export default {
  async getAlternativesByQuestionIdAction({commit}, questionId) {
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }
    
    return await axios.get(`${URI_BASE}${routes.getByQuestionId}/${questionId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
  }
}