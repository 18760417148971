<template>
  <div class="i5-controls">
    <div class="i5-buttons i5-buttons-superior">
      <button @click.prevent="openModalCreateUpdateTest" class="button adicionar waves-effect">Adicionar novo</button>
    </div>
  </div>

  <div class="build-content i5_animated i5_fadeInRight">
    <div class="build-datagrid-cards build-datagrid-cards-3">
      <ul class="list-column-md">
        <li v-for="(test, key) in sortTest" :key="key">
          <div>
            <div class="info">
              <div>
                <router-link :to="{name: 'test', params: {id: test.id,}}">
                <div class="title"><span style="font-size: 12px; color: #7c7c7c;">PROVA</span><br> {{ `${test.institutionName} - ${test.yearName}` }}</div>
                  Clique para visualizar as questões
                </router-link>
              </div>
            </div>
            <div class="i5-controls-default">
              <button @click.prevent="openModalCreateUpdateTest(test)" title="Editar" class="editar waves-effect"><i class="fas fa-pencil-alt"></i> Editar</button>
              <button @click.prevent="openConfirmationModal(test.id)" title="Remover" class="remover waves-effect"><i class="fas fa-times"></i> Excluir</button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <create-update-tests-modal 
    v-if="modalCreateUpdateTest == true"
    :test="test"
    @closeModal="closeModalCreateUpdateTest"
  />

  <confirmation-modal
    v-if="executeConfirmationModal"
    :text="confirmationText"
    :id="testId"
    @destroy="destroyConfirmation"
    @closeModal="closeConfirmationModal"
  />
</template>
<script>
import { mapActions, mapState } from 'vuex';
import CreateUpdateTestsModal from '@/components/tests/CreateUpdateTestsModal.vue';
import { TOKEN } from '@/config/api';
import ConfirmationModal from '@/components/confirmation/ConfirmationModal.vue';

export default {
  name: 'TestsList',

  props: {
    getTestsAfterFilter: false,
  },

  emits: {
    showLoader: null,
    hideLoader: null,
  },

  data() {
    return {
      formData: {
        name: '',
      },
      errors: null,
      modalCreateUpdateTest: false,
      executeToast: false,
      textToast: '',
      descriptionToast: '',
      typeToast: '',
      executeConfirmationModal: false,
      confirmationText: '',
      testId: null,
    }
  },

  components: {
    CreateUpdateTestsModal,
    ConfirmationModal,
  },

  mounted() {
    if (this.testsState.tests.length == 0 || this.getTestsAfterFilter) {
      this.getTests()
    }
  },

  computed: {
    ...mapState({
      testsState: state => state.tests,
    }),
    sortTest() {
      if(this.testsState.tests.length){
        return this.testsState.tests.sort((a,b)=>{
          if (a.institutionName < b.institutionName) {
            return -1;
          } else if (a.institutionName > b.institutionName) {
            return 1;
          } else {
            return a.yearName - b.yearName;
          }
        });
      }
      return [];
    }
  },

  methods: {
    ...mapActions([
      'getTestsAction',
      'destroyTestAction',
    ]),

    async getTests() {
      try {
        this.showLoaderEmiter();
        return await this.getTestsAction();
      } catch(error) {
        if (error.response.status == 401) {
          window.localStorage.removeItem(TOKEN)
          this.$router.push({name: 'home'})
          this.$store.commit('toast', {execute: true, text: 'Sua sessão expirou! Faça o login novamente', type: 'danger'})
        }
      } finally {
        this.hideLoaderEmiter();
      }
    },

    async destroyTest(id) {
      try {
        this.showLoaderEmiter();
        await this.destroyTestAction(id)
        this.$store.commit('toast', {execute: true, text: 'Prova exlcuída com sucesso', type: 'success'})
        return this.getTests();
      } catch(error) {
        this.$store.commit('toast', {execute: true, text: 'Houve um erro ao excluir a prova', type: 'danger'})     
      } finally {
        this.hideLoaderEmiter();
      }
    },

    openConfirmationModal(id) {
      this.executeConfirmationModal = true;
      this.testId = id;
      this.confirmationText = 'Essa prova pode ter questões cadastradas. Deseja realmente excluir?'
    },

    closeConfirmationModal() {
      this.executeConfirmationModal = false;
    },

    destroyConfirmation(id) {
      this.closeConfirmationModal();
      this.destroyTest(id);
    },

    openModalCreateUpdateTest(test) {      
      this.test = test;
      this.modalCreateUpdateTest = true;
    },

    closeModalCreateUpdateTest(reload = null) {
      this.modalCreateUpdateTest = false;
      this.test = {};

      if (reload) {
        return this.getTests();
      }
    },

    showLoaderEmiter() {
      return this.$emit('showLoader')
    },

    hideLoaderEmiter() {
      return this.$emit('hideLoader');
    },
  }
}
</script>