<template>

    <loader
      v-if="executeLoader"
    />
  
    <div class="i5-breadcrumb" style="margin-bottom: .5rem;">
      <ul>
        <li>você está em:</li>
        <li>SIMULADO - {{ this.mockTest.name }}</li>
        <li>{{ `ADICIONAR QUESTÕES` }}</li>
      </ul>
    </div>
    <div class="i5-buttons i5-buttons-superior" style="margin-bottom: 1rem;">
      <router-link :to="{name: 'simulado'}" class="button adicionar waves-effect">Voltar</router-link>
    </div>

    <filter-questions-for-mock-tests
      :mockTestId="this.mockTest.id"
      @cleanFilter="cleanFilter"
      @showLoader="showLoader"
      @hideLoader="hideLoader"
    />

    <add-questions-for-mock-tests
      :key="filterCleaner"
      :mockTestId="this.mockTest.id"
      @showLoader="showLoader"
      @hideLoader="hideLoader"
    />
  
  </template>
  
  <script>
  import { mapActions, mapState } from 'vuex';
  import Loader from '@/components/loader/Loader.vue';
  import { createToast } from 'mosha-vue-toastify';
  import 'mosha-vue-toastify/dist/style.css'
  import AddQuestionsForMockTests from '@/components/mockTests/AddQuestionsForMockTests.vue';
  import FilterQuestionsForMockTests from '@/components/mockTests/FilterQuestionsForMockTests.vue';
  
  export default {
    data() {
      return {
        mockTest: {
          id: null,
          name: '',
          time: null,
          start_date: '',
          end_date: '',
        },
        filterCleaner: 0,
        executeLoader: false,
      }
    },  
  
    components: {
      Loader,
      AddQuestionsForMockTests,
      FilterQuestionsForMockTests
    },
  
    mounted() {
      this.getMockTest()
    },
  
    methods: {
      ...mapActions([
        'getMockTestAction',
      ]),

      async getMockTest() {
        try {
            this.showLoader();
            const response = await this.getMockTestAction(this.$route.params.id);
            this.mockTest = response.data
        } catch (error) {
            this.toast(error, 'danger');
        } finally {
            this.hideLoader();
        }
      },
  
      cleanFilter() {
        this.getQuestionsAfterFilter = true;
        this.filterCleaner++
      },
  
      showLoader() {
        return this.executeLoader = true;
      },
  
      hideLoader() {
        return this.executeLoader = false;
      },
  
      toast(text, type, description = '') {
        return createToast(
          {
            title: text,
            description: description,
          },
          {
            type: type,
            transition: 'zoom',
            timeout: 3000,
        })
      }
    },
  }
  </script>