<template>
    <form action="" @submit.prevent="onSubmit">
      <div class="form-grid">
        <div class="input-12">
          <div class="input-custom">
            <span style="font-size: 18px; font-weight: bold; display: block"
              >Buscar questão:</span
            >
          </div>
        </div>
      </div>
  
      <div class="form-grid">
        <div class="input-6">
          <select-medicine-area
            @sendMedicineAreaId="sendMedicineAreaId"
            :recievedMedicineAreaId="filters.medicine_area_id"
            :filter="filters.medicine_area_id"
            :key="cleanFilter"
          />
        </div>
        <div class="input-6">
          <!-- :key="cleaning == true ? cleanFilter : reduceSpecialties" -->
          <select-specialties
            @sendSpecialtyId="sendSpecialtyId"
            :recievedSpecialtyId="filters.specialty_id"
            :recievedMedicineAreaId="filters.medicine_area_id"
            :filter="filters.specialty_id"
            :key="reduceSpecialties"
          />
        </div>
      </div>
  
      <div class="form-grid">
        <div class="input-6">
          <!-- :key="cleaning == true ? cleanFilter : reduceThemes" -->
          <select-themes
            @sendThemeId="sendThemeId"
            :recievedThemeId="filters.theme_id"
            :recievedSpecialtyId="filters.specialty_id"
            :filter="filters.theme_id"
            :key="reduceThemes"
          />
        </div>
        <div class="input-6">
          <select-tests
            @sendTestId="sendTestId"
            :recieveTestId="filters.test_id"
            :key="cleanFilter"
          />
        </div>
      </div>
      <div class="form-grid">
        <div class="input-6">
          <label for="">Trecho da questão</label>
          <input type="text" name="questionSlice" v-model="filters.question" />
        </div>
      </div>
    </form>
  
    <div class="form-grid" style="padding-top: 25px">
      <div class="input-12">
        <div class="i5-buttons i5-buttons-right">
          <button
            :disabled="validations == false"
            @click.prevent="search"
            class="button confirmar waves-effect"
          >
            Filtrar
          </button>
          <button
            @click.prevent="cleanSearch"
            class="button default waves-effect"
          >
            Limpar filtros
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions } from "vuex";
  import { createToast } from "mosha-vue-toastify";
  import "mosha-vue-toastify/dist/style.css";
  
  import SelectInstitution from "@/components/institutions/SelectInstitutions.vue";
  import SelectMedicineArea from "@/components/medicineAreas/SelectMedicineAreas.vue";
  import SelectThemes from "@/components/themes/SelectThemes.vue";
  import SelectYears from "@/components/years/SelectYears.vue";
  import SelectSpecialties from "../specialties/SelectSpecialties.vue";
  import SelectTests from "../tests/SelectTests.vue";
  
  export default {
    name: "FilterQuestionsForMockTests",
  
    props: {
      filter: {
        theme_id: 0,
        institution_id: 0,
        specialty_id: 0,
        year_id: 0,
        medicine_area_id: 0,
      },
      mockTestId: false,
      getById: false,
    },
  
    emits: {
      cleanFilter: null,
      showLoader: null,
      hideLoader: null,
    },
  
    components: {
      SelectInstitution,
      SelectMedicineArea,
      SelectThemes,
      SelectYears,
      SelectSpecialties,
      SelectTests
    },
  
    data() {
      return {
        filters: {
          institution_id: 0,
          medicine_area_id: 0,
          specialty_id: 0,
          theme_id: 0,
          year_id: 0,
          test_id: 0,
          question: '',
        },
        test: {
            id: null,
            institution_id: 0,
            year_id: 0,
        },
        cleanFilter: 0,
        validations: false,
        reduceSpecialties: 0,
        reduceThemes: 0,
        testInformation: {},
        cleaning: false,
      };
    },
  
    watch: {
      filters: {
        handler: function (param) {
          if (param.question != '') {
            return (this.validations = true);
          }
  
          if (param.theme_id != 0) {
            return (this.validations = true);
          }
  
          if (param.institution_id != 0) {
            return (this.validations = true);
          }
  
          if (param.specialty_id != 0) {
            return (this.validations = true);
          }
  
          if (param.year_id != 0) {
            return (this.validations = true);
          }
  
          if (param.medicine_area_id != 0) {
            return (this.validations = true);
          }

          if (param.test_id != 0) {
            return (this.validations = true);
          }
  
          this.validations = false;
        },
        deep: true,
      },
    },
  
    mounted() {
      //if (this.test.id == null) {
        //this.getQuestions();
      //}
    },

    methods: {
      ...mapActions([
        'searchQuestionsForMockTestAction',
        'getQuestionsByMockTestIdAction',
        'getTestsAction',
      ]),
  
      async getQuestions() {
        try {
          this.showLoaderEmiter();
          await this.searchQuestionsForMockTestAction(this.filters);
        } catch (error) {
          console.log(error)
          this.toast('Houve um erro ao buscar questões', 'danger');
        } finally {
          this.hideLoaderEmiter();
        }
      },
  
      async search() {
        try {
          let response;
          this.showLoaderEmiter();
          if(this.mockTestId && this.getById){
            this.filters.mock_test_id = this.mockTestId;
            this.filters.perPage = 150;
            response = await this.getQuestionsByMockTestIdAction(this.filters);
          }else{
            this.filters.mock_test_id = this.mockTestId;
            this.filters.perPage = 150;
            response = await this.searchQuestionsForMockTestAction(this.filters);
          }
  
          if (response.data.length == 0) {
            this.toast("Não encontramos questões com os filtros aplicados", "warning");
            return this.cleanSearch();
          }
        } catch (error) {
          console.log(error)
          return this.toast("Houve um erro ao buscar as questões", "danger");
        } finally {
          this.hideLoaderEmiter();
        }
      },
  
      cleanSearch() {
        this.cleaning = true;
        this.cleanFilter++;
        this.filters.question = '';
        this.filters.theme_id = 0;
        this.filters.institution_id = 0;
        this.filters.specialty_id = 0;
        this.filters.year_id = 0;
        this.filters.medicine_area_id = 0;
        this.filters.test_id = 0;
        this.validations = false;
        this.sendMedicineAreaId(0)
        this.sendSpecialtyId(0)
        this.sendThemeId(0)
        this.sendTestId(0)
        return this.$emit("cleanFilter");
      },
  
      showLoaderEmiter() {
        return this.$emit("showLoader");
      },
  
      hideLoaderEmiter() {
        return this.$emit("hideLoader");
      },
  
      sendInstitutionId(institutionId) {
        this.filters.institution_id = institutionId;
      },
  
      sendMedicineAreaId(medicineAreaId) {
        this.reduceSpecialties++;
        this.filters.medicine_area_id = medicineAreaId;
      },
  
      sendSpecialtyId(specialtyId) {
        this.reduceThemes++
        this.filters.specialty_id = specialtyId;
      },
  
      sendYearId(yearId) {
        this.filters.year_id = yearId;
      },
  
      sendThemeId(themeId) {
        this.filters.theme_id = themeId;
      },

      sendTestId(testId){
        this.filters.test_id = testId;
      },
  
      toast(text, type, description = "") {
        return createToast(
          {
            title: text,
            description: description,
          },
          {
            type: type,
            transition: "zoom",
            timeout: 3000,
          }
        );
      },
    },
  };
  </script>