<template>
  <div class="i5-modal i5-modal-ativo">
    <div class="i5-modal-wrap in">
      <div class="i5-modal-fechar" title="fechar" @click.prevent="closeModal"><i class="fas fa-times"></i></div>
      <div class="i5-modal-header">{{ medicineArea.id ? 'Nova Área da Medicina' : 'Atualizar Área da Medicina' }}</div>
      <div class="i5-modal-content">
        
        <div class="form-grid">
          <div class="input-12 required">
            <label for="theme_id">Área da Medicina</label>
            <input type="text" name="medicine_area_name" id="medicine_area_id" v-model="formData.name" />
          </div>
        </div>

      </div>
      
      <div class="i5-modal-controls">
        <div class="i5-buttons i5-buttons-right">
          <button
            @click.prevent="medicineArea.id == null ? storeMedicineArea() : updateMedicineArea(medicineArea.id)"
            class="button adicionar waves-effect"
            :disabled="validations == false"
          >
            {{ medicineArea.id == null ? 'Salvar' : 'Atualizar' }}
          </button>
          <button @click.prevent="closeModal" class="button default cancelar waves-effect">Cancelar</button>
        </div>
      </div>
    </div>
  </div>

  <loader
    v-if="executeLoader"
  />
</template>

<script>
import { mapActions } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

import Loader from '@/components/loader/Loader.vue';

export default {
  name: 'CreateUpdateMedicineAreasModal',

  props: {
    medicineArea: {
      id: null,
      name: '',
    },
  },

  emits: {
    closeModal: null,
  },

  components: {
    Loader,
  },

  watch: {
    formData: {
      handler: function(param) {

        if (this.medicineArea.id != null) {
          if ((param.id != undefined && param.name != undefined) && param.name != '') {
            return this.validations = true;
          }
          return this.validations = false;
        }

        if (this.formData.name != undefined && this.formData.name != '') {
          return this.validations = true;
        }
      },
      deep: true,
    }
  },

  data() {
    return {
      formData: {
        id: null,
        name: '',
      },
      executeLoader: false,
      validations: false,
    }
  },

  mounted() {
    this.fetchMedicineAreaData()
  },

  methods: {
    ...mapActions([
      'storeMedicineAreaAction',
      'updateMedicineAreaAction',
      'destroyMedicineAreaAction',
    ]),

    fetchMedicineAreaData() {
      this.formData.id = this.medicineArea.id;
      this.formData.name = this.medicineArea.name;
    },

    async storeMedicineArea() {
      try {
        this.executeLoader = true;
        await this.storeMedicineAreaAction(this.formData);
        this.toast('Área da Medicina cadastrada com sucesso.', 'success');
        return this.closeModal(true);
      } catch(error) {
        return this.toast('Houve um erro ao cadastrar a área da medicina.', 'danger');

      } finally {
        this.executeLoader = false;
      }
    },

    async updateMedicineArea(id) {
      try {
        this.executeLoader = true;
        await this.updateMedicineAreaAction({id, data: this.formData});
        this.toast('Área da Medicina alterada com sucesso', 'success');
        return this.closeModal(true);
      } catch(error) {        
        this.toast('Houve um problema para alterar a área da medicina', 'danger');
      } finally {
        this.executeLoader = false;
      }
    },

    closeModal(reload = null) {
      this.$emit('closeModal', reload)
    },

    toast(text, type, description = '') {
      return createToast(
        {
          title: text,
          description: description,
        },
        {
          type: type,
          transition: 'zoom',
          timeout: 3000,
      })
    }
  }
}
</script>