<template>
  <div class="i5-controls">
    <div class="i5-buttons i5-buttons-superior">
      <button @click.prevent="openModalCreateUpdateMedicineArea" class="button adicionar waves-effect">Adicionar novo</button>
    </div>
  </div>

  <div class="build-content i5_animated i5_fadeInRight">
    <div class="build-datagrid-default">
      <ul>
        
        <li v-for="(medicineArea, key) in medicineAreasState.medicineAreas" :key="key">
          <div>
            <div class="info">
              <div>
                <span>ID:</span>
                <span>{{ medicineArea.id }}</span>
              </div>

              <div>
                <span>Título:</span>
                <span>{{ medicineArea.name }}</span>
              </div>
            </div>
    
            <div class="i5-controls-default">
              <button @click.prevent="openModalCreateUpdateMedicineArea(medicineArea)" title="Editar" class="editar waves-effect"><i class="fas fa-pencil-alt"></i> Editar</button>
              <button @click.prevent="destroyMedicineArea(medicineArea.id)" title="Remover" class="remover waves-effect"><i class="fas fa-times"></i> Excluir</button>
            </div>
          </div>
        </li>

      </ul>
    </div>
  </div>

  <create-update-medicine-areas-modal
    v-if="modalCreateUpdateMedicineArea == true"
    :medicineArea="medicineArea"
    @closeModal="closeModalCreateUpdateMedicineArea"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

import CreateUpdateMedicineAreasModal from '@/components/medicineAreas/CreateUpdateMedicineAreasModal.vue';
import MedicineAreasFilter from '@/components/medicineAreas/MedicineAreasFilter.vue';

export default {
  name: 'MedicineAreaListComponent',

  props: {
    getMedicineAreasAfterFilter: false,
  },

  emits: {
    showLoader: null,
    hideLoader: null,
  },

  data() {
    return {
      formData: {
        name: '',
      },
      errors: null,
      modalCreateUpdateMedicineArea: false,
      medicineArea: {},

    }
  },

  components: {
    CreateUpdateMedicineAreasModal,
    MedicineAreasFilter
  },

  mounted() {
    if (this.medicineAreasState.medicineAreas.length == 0 || this.getMedicineAreasAfterFilter) {
      this.getMedicineAreas()
    }
  },

  computed: {
    ...mapState({
      medicineAreasState: state => state.medicineAreas,
    }),
  },

  methods: {
    ...mapActions([
      'getMedicineAreasAction',
      'destroyMedicineAreaAction',
    ]),

    async getMedicineAreas() {
      try {
        this.showLoaderEmiter();
        return await this.getMedicineAreasAction();
      } catch(error) {
        this.toast('Houve um erro ao buscar as áreas da medicina', 'danger');
      } finally {
        this.hideLoaderEmiter();
      }
    },

    async destroyMedicineArea(id) {
      try {
        this.showLoaderEmiter();
        await this.destroyMedicineAreaAction(id)
        this.toast('Área da Medicina exlcuída com sucesso', 'success');
        return this.getMedicineAreas();
      } catch(error) {
        return this.toast('Houve um erro ao excluir a área da medicina', 'danger');
      } finally {
        this.hideLoaderEmiter();
      }
    },

    openModalCreateUpdateMedicineArea(medicineArea) {      
      this.medicineArea = medicineArea;
      this.modalCreateUpdateMedicineArea = true;
    },

    closeModalCreateUpdateMedicineArea(reload = null) {
      this.modalCreateUpdateMedicineArea = false;
      this.medicineArea = {};

      if (reload) {
        return this.getMedicineAreas();
      }
    },

    showLoaderEmiter() {
      return this.$emit('showLoader')
    },

    hideLoaderEmiter() {
      return this.$emit('hideLoader');
    },

    toast(text, type, description = '') {
      return createToast(
        {
          title: text,
          description: description,
        },
        {
          type: type,
          transition: 'zoom',
          timeout: 3000,
      })
    }
  }
}
</script>