<template>
  <div class="i5-modal i5-modal-ativo">
    <div class="i5-modal-wrap in">
      <div class="i5-modal-fechar" title="fechar" @click.prevent="closeModal"><i class="fas fa-times"></i></div>
      <div class="i5-modal-header">
        Confirme antes de excluir
      </div>
      <div class="i5-modal-content">
        <div>
          {{ text }}
        </div>
      </div>
      
      <div class="i5-modal-controls">
        <div class="i5-buttons i5-buttons-right">
          <button
            class="button adicionar waves-effect"
            @click.prevent="destroy"
          >
            Excluir  
          </button>
          <button
            class="button default cancelar waves-effect"
            @click.prevent="closeModal"
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: '',
    id: null,
  },

  mounted() {
  },

  methods: {
    closeModal() {
      this.$emit('closeModal');
    },

    destroy() {
      this.$emit('destroy', this.id);
    }
  },
}
</script>