<template>
  <div class="form-grid">
    <div class="input-12">
      <div class="input-custom">
        <span style="font-size:18px;font-weight:bold;display:block;margin:0 0 15px;">Filtre por áreas da medicina:</span>
      </div>
    </div>

    <div class="input-12">
      <label for="medicine_area_id">Área da Medicina: </label>
      <input type="text" name="medicine_area_name" id="medicine_area_id" v-model="filters.name" />
    </div>
  </div>

  <div class="form-grid">
    <div class="input-12">
      <div class="i5-buttons i5-buttons-right">
        <button @click.prevent="search" class="button confirmar waves-effect">Filtrar</button>
        <button @click.prevent="cleanSearch" class="button default waves-effect">Limpar filtros</button>
      </div>
    </div>
  </div>
</template>
<script>

import { mapActions } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default {
  name: 'ThemesFilter',

  props: {
    filter: {
      name: '',
    },
  },

  emits: {
    cleanFilter: null,
    showLoader: null,
    hideLoader: null,
  },

  data() {
    return {
      filters: {
        name: '',
      },

      cleanFilter: 0,
    }
  },

  mounted() {
    
  },

  methods: {
    ...mapActions([
      'searchMedicineAreasAction',
    ]),

    async search() {
      try {
        const response = await this.searchMedicineAreasAction(this.filters);

        if (response.data.length == 0) {
          this.toast('Não encontramos o tema filtrado', 'warning');
          return this.cleanSearch();
        }
      } catch(error) {
        return this.toast('Houve um erro ao buscar os temas', 'danger');
      }
    },

    cleanSearch() {
      this.cleanFilter++;
      this.filters.name = '';
      return this.$emit('cleanFilter');
    },

    showLoaderEmiter() {
      return this.$emit('showLoader')
    },

    hideLoaderEmiter() {
      return this.$emit('hideLoader');
    },

    toast(text, type, description = '') {
      return createToast(
        {
          title: text,
          description: description,
        },
        {
          type: type,
          transition: 'zoom',
          timeout: 3000,
      })
    }
  }
}
</script>