<template>
  <div class="form-grid">
    <div class="input-12">
      <div class="input-custom">
        <span style="font-size:18px;font-weight:bold;display:block;margin:0 0 15px;">Escolha os filtros:</span>
      </div>
    </div>

    <div class="input-12">
      <label for="theme_id">Ano: </label>
      <input type="text" name="theme_name" id="theme_id" v-model="filters.name" />
    </div>
  </div>

  <div class="form-grid">
    <div class="input-12">
      <select-institutions
        :filter="filters.institution_id"
        @sendInstitutionId="sendInstitutionId"
        :key="cleanFilter"
      />
    </div>
  </div>

  <div class="form-grid">
    <div class="input-12">
      <div class="i5-buttons i5-buttons-right">
        <button @click.prevent="search" class="button confirmar waves-effect">Filtrar</button>
        <button @click.prevent="cleanSearch" class="button default waves-effect">Limpar filtros</button>
      </div>
    </div>
  </div>
</template>
<script>

import { mapActions } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

import SelectInstitutions from '@/components/institutions/SelectInstitutions.vue';

export default {
  name: 'ThemesFilter',

  props: {
    filter: {
      name: '',
      institution_id: 0,
    },
  },

  emits: {
    cleanFilter: null,
    showLoader: null,
    hideLoader: null,
  },

  components: {
    SelectInstitutions,
  },

  data() {
    return {
      filters: {
        name: '',
        institution_id: 0,
      },

      cleanFilter: 0,
    }
  },

  mounted() {
    
  },

  methods: {
    ...mapActions([
      'searchYearsAction',
    ]),

    async search() {
      try {
        this.showLoaderEmiter();
        const response = await this.searchYearsAction(this.filters);

        if (response.data.length == 0) {
          this.toast('Não encontramos o ano filtrado', 'warning');
        }
      } catch(error) {
        return this.toast('Houve um erro ao buscar os anos', 'danger');
      } finally {
        this.hideLoaderEmiter();
      }
    },

    cleanSearch() {
      this.cleanFilter++;
      this.filters.name = '';
      this.filters.institution_id = 0;
      this.$emit('cleanFilter');
    },

    sendInstitutionId(institutionId) {
      this.filters.institution_id = institutionId;
    },

    showLoaderEmiter() {
      return this.$emit('showLoader')
    },

    hideLoaderEmiter() {
      return this.$emit('hideLoader');
    },

    toast(text, type, description = '') {
      return createToast(
        {
          title: text,
          description: description,
        },
        {
          type: type,
          transition: 'zoom',
          timeout: 3000,
      })
    }
  }
}
</script>