import axios from 'axios';
import { URI_BASE, TOKEN } from '@/config/api';

const routes = {
  medicineAreas: 'medicine-areas/all',
  store: 'medicine-areas/store',
  update: 'medicine-areas/update',
  destroy: 'medicine-areas/destroy',
  search: 'medicine-areas/search'
};

export default {
  async getMedicineAreasAction({commit}) { 
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }

    const medicineAreas = await axios.get(`${URI_BASE}${routes.medicineAreas}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    commit('MEDICINE_AREAS', medicineAreas.data);

    return medicineAreas;
  },

  async storeMedicineAreaAction({commit}, data) {    
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }

    return await axios.post(`${URI_BASE}${routes.store}`, data, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
  },

  async updateMedicineAreaAction({commit}, params) {
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }

    return await axios.put(`${URI_BASE}${routes.update}/${params.id}`, params.data, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
  },

  async destroyMedicineAreaAction({commit}, id) {
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }
  
    return await axios.delete(`${URI_BASE}${routes.destroy}/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
  },

  async searchMedicineAreasAction({commit}, params) { 
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }

    const medicineAreas = await axios.get(`${URI_BASE}${routes.search}?name=${params.name}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    commit('MEDICINE_AREAS', medicineAreas.data);

    return medicineAreas;
  },
}