import { createStore } from 'vuex'

import login from './modules/Login/index';
import questions from './modules/Questions/index';
import institutions from './modules/Institutions/index';
import medicineAreas from './modules/MedicineAreas/index';
import themes from './modules/Themes/index';
import years from './modules/Years/index';
import specialties from './modules/Specialties/index';
import loader from './modules/Loader/index';
import alternatives from './modules/Alternatives/index';
import tests from './modules/Tests/index';
import users from './modules/Users/index';
import mockTests from './modules/MockTests/index';

export default createStore({
  state: {
    // confirmation modal
    executeConfirmation: false,
    confirmationText: '',
    confirmation: false,
  },
  getters: {
  },
  mutations: {
    // toast
    toast(state, params) {
      state.executeToast = params.execute;
      state.text = params.text;
      state.description = params.description || '';
      state.type = params.type;
    },

    destroyToast(state, executeToast) {
      state.executeToast = executeToast;
    },    
  },
  actions: {
  },
  modules: {
    login,
    questions,
    institutions,
    medicineAreas,
    themes,
    years,
    specialties,
    loader,
    alternatives,
    tests,
    users,
    mockTests
  },
  
})
