<template>
  <div class="i5-modal i5-modal-ativo">
    <div class="i5-modal-wrap in">
      <div class="i5-modal-fechar" title="fechar" @click.prevent="closeModal"><i class="fas fa-times"></i></div>
      <div class="i5-modal-header">{{ question.id == null ? 'Nova questão' : 'Atualizar questão' }}</div>
      <div class="i5-modal-content">
        
        <div class="form-grid">
          <input type="checkbox" name="is_discursive" id="is_discursive" v-model="formData.is_discursive">
          <label for="is_discursive">
            <span></span>
            <span>Essa questão é discursiva?</span>
          </label>
        </div>

        <div class="form-grid">
          <div class="input-12 required">
            <label for="question">Questão:</label>
            <textarea name="question" id="question" cols="30" rows="10" v-model="formData.question"></textarea>
          </div>
        </div>

        <div class="form-grid required">
          <div class="i5-buttons">
            <button type="button" @click="addAlternative()" class="button adicionar">
              Adicionar nova alternativa
            </button>
          </div>
        </div>
        <div style="margin:0 0 15px;" v-for="(alternative, key, index) in formData.alternatives" :key="index">
          <div class="form-grid">
            <div class="input-12 required">
              <label for="alternative">{{ `Alternativa` }}</label>
            </div>
          </div>

          <div class="form-grid">
            <div class="input-12">
              <input v-model="alternative.alternative" />
            </div>
          </div>

          <div class="form-grid">
            <div class="input-10">
              <input
                type="radio"
                name="is_correct"
                :value="alternative.is_correct"
                :checked="alternative.is_correct"
                :id="key"
                v-model="alternative.is_correct"
              />
              
              <label :for="key">
                <span></span>
                <span>Essa alternativa é a correta?</span>
              </label>
            </div>

            <div class="input-2">
              <div class="i5-controls i5-controls-compact">
                <button type="button" @click.prevent="removeAlternative(key, alternative.id)" v-show="index != 0" class="button remover" title="Remover">
                  <i class="fas fa-times"></i> Remover
                </button>
              </div>
            </div>
          </div>
        </div>
        
        <div class="form-grid" v-if="formData.is_discursive == false">
          <div class="input-12 required">
            <label for="foo1">Gabarito:</label>
            <textarea name="question" id="question" cols="30" rows="10" v-model="formData.explanation"></textarea>
          </div>
        </div>
        
        <div class="form-grid">
          <div class="input-12 required">
            <select-medicine-area
              @sendMedicineAreaId="getMedicineAreaId"
              :recievedMedicineAreaId="question.id != null ? question.medicine_area_id : formData.medicine_area_id"
            />
          </div>
        </div>

        <div class="form-grid">
          <div class="input-12 required">
            <select-specialties
              @sendSpecialtyId="getSpecialtyId"
              :recievedSpecialtyId="question.id != null ? question.specialty_id : formData.specialty_id"
              :recievedMedicineAreaId="question.id != null ? question.medicine_area_id : formData.medicine_area_id"
              :key="reduceSpecialties"
            />
          </div>
        </div>
        
        <div class="form-grid">
          <div class="input-12 required">
            <select-themes
              @sendThemeId="getThemeId"
              :recievedThemeId="question.id != null ? question.theme_id : formData.theme_id"
              :recievedSpecialtyId="question.id != null ? question.specialty_id : formData.specialty_id"
              :key="reduceThemes"
            />
          </div>
        </div>

        <div class="form-grid">
          <div class="input-12 required">
            <select-institution
              @sendInstitutionId="getInstitutionId"
              :recievedInstitutionId="question.id != null ? question.institution_id : formData.institution_id"
            />
          </div>
        </div>
        
        <div class="form-grid">
          <div class="input-12 required">
            <select-years
              @sendYearId="getYearId"
              :recievedYearId="question.id != null ? question.year_id : formData.year_id"
              :recievedInstitutionId="question.id != null ? question.institution_id : formData.institution_id"
              :key="reduceYears"
            />
          </div>
        </div>

        <div class="form-grid">
          <div class="input-12 required">
            
            <div class="i5-image-upload" @dragover="dragover" @dragleave="dragleave" @drop="drop">
              <div class="i5-image-upload-list">
                <label for="file">
                  {{ formData.image != '' ? 'Clique aqui ou arraste para substituir esta imagem ou' : 'Clique aqui ou arraste para adicionar uma imagem ou' }}
                </label>

                <div class="form-grid">
                  <div class="input-12">
                    <input :placeholder="formData.image == '' ? 'Copie e cole uma imagem aqui...' : 'Copie e cole para substituir...'" @paste="handleFileObject" />
                  </div>
                </div>

                <ul id="i5-image-upload-list-id">
                </ul>
              </div>

              <div class="form-grid">
                <div class="input-12">
                  <input type="file" id="file" ref="file" @click="initiateFileObject()" @change="handleFileObject()" v-if="pastedFile == false" />
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="form-grid" v-if="formData.image != ''">
          <div class="input-12">
            <strong>ID:</strong> {{ formData.id }}<br />
            <strong>URL:</strong> <a :href="uri_image+formData.image" target="_blank">{{ uri_image+formData.image }}</a>
          </div>
        </div>

        <div class="form-grid" v-if="formData.image != ''">
          <div class="input-12">
            <img :src="uri_image+formData.image" alt="" border="0" style="max-width:50%; display:block; margin:auto;" />
          </div>

          <div class="input-12">
            <div class="i5-controls i5-controls-default" style="justify-content:center; padding:15px 0 0;">
              <button @click.prevent="removeFile()" title="Remover" class="remover waves-effect"><i class="fas fa-times"></i> Remover imagem</button>
            </div>
          </div>
        </div>

      </div>
      
      <div class="i5-modal-controls">
        <div class="i5-buttons i5-buttons-right">
          <button
            @click.prevent="question.id == null ? storeQuestion() : updateQuestion(question.id)"
            class="button adicionar waves-effect">
            {{ question.id == null ? 'Salvar' : 'Atualizar' }}
          </button>
          <button @click.prevent="closeModal()" class="button default waves-effect">Fechar</button>
        </div>
      </div>
    </div>
  </div>

  <loader
    v-if="executeLoader"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import { URI_IMAGE } from '@/config/api';

import Loader from '@/components/loader/Loader.vue';
import SelectInstitution from '@/components/institutions/SelectInstitutions.vue';
import SelectMedicineArea from '@/components/medicineAreas/SelectMedicineAreas.vue';
import SelectThemes from '@/components/themes/SelectThemes.vue';
import SelectYears from '@/components/years/SelectYears.vue';
import SelectSpecialties from '@/components/specialties/SelectSpecialties.vue'

export default {
  name: 'CreateUpdateQuestionsModal',

  props: {
    question: {
      id: null,
      question: '',
      alternatives: [{
        alternative: "",
        is_correct: false,
      }],
      is_discursive: 0,
      theme_id: 0,
      year_id: 0,
      specialty_id: 0,
      medicine_area_id: 0,
      institution_id: 0,
      explanation: '',
      image: '',
    },
  },

  components: {
    Loader,
    SelectInstitution,
    SelectMedicineArea,
    SelectThemes,
    SelectYears,
    SelectSpecialties,
  },

  emits: {
    closeModal: null,
  },

  data() {
    return {
      formData: {
        question: '',
        alternatives: [{
          alternative: "",
          is_correct: false,
        }],
        remove_alternatives: [],
        is_discursive: 0,
        theme_id: 0,
        year_id: 0,
        specialty_id: 0,
        medicine_area_id: 0,
        institution_id: 0,
        explanation: '',
        image: '',
      },
      executeLoader: false,
      validations: false,
      file: null,
      fileName: null,
      inputs: 0,
      reduceSpecialties: 0,
      reduceThemes: 0,
      reduceYears: 0,
      pastedFile: false,
      uri_image: URI_IMAGE,
    }
  },

  // watch: {
  //   formData: {
  //     handler: function(param) {
  //       if (this.question.id != null) {
  //         if (param.id != undefined && param.question != undefined) {
  //           return this.validations = true;
  //         }
  //       }

  //       if (this.formData.question != undefined) {
  //         return this.validations = true;
  //       }
  //     },
  //     deep: true,
  //   }
  // },

  mounted() {
    if (this.question.id != null) {
      this.getAlternatives();
      return this.fetchQuestionData();
    }
    
  },

  methods: {
    ...mapActions([
      'storeQuestionAction',
      'updateQuestionAction',
      'destroyQuestionAction',
      'getAlternativesByQuestionIdAction',
    ]),

    async getAlternatives() {
      try {
        this.executeLoader = true;
        const response = await this.getAlternativesByQuestionIdAction(this.question.id);
        return await this.fetchAlternatives(response.data);
      } catch (error) {
        this.toast('Houve um erro ao buscar as alternativas da questão', 'danger');
      } finally {
        this.executeLoader = false;
      }
    },

    fetchQuestionData() {      
      this.formData.id = this.question.id;
      this.formData.question = this.question.question;
      this.formData.is_discursive = this.question.is_discursive;
      this.formData.theme_id = this.question.theme_id;
      this.formData.year_id = this.question.year_id;
      this.formData.specialty_id = this.question.specialty_id;
      this.formData.medicine_area_id = this.question.medicine_area_id;
      this.formData.institution_id = this.question.institution_id;
      this.formData.explanation = this.question.explanation;
      this.formData.image = this.question.image == null ? '' : `${this.question.image}`;
    },

    async fetchAlternatives(alternatives) {
      await alternatives.map(alternative => {
        alternative.is_correct = alternative.is_correct == 1 ? true : false;
      });

      return this.formData.alternatives = alternatives;
    },

    async storeQuestion() {
      try {
        let data = new FormData();
            
        if (this.file != null) {
          data.append('file', this.file);
        }

        this.formData.alternatives = JSON.stringify(this.formData.alternatives);
    
        for (let param in this.formData) {
          data.append(param, this.formData[param]);
        }

        this.executeLoader = true;
        await this.storeQuestionAction(data);
        this.toast('Questão cadastrada com sucesso.', 'success');
        this.executeLoader = false;
        return this.closeModal(true);
      } catch(error) {
        this.executeLoader = false;
        return this.toast('Houve um erro ao cadastrar a questão.', 'danger');
      }
    },

    async updateQuestion(id) {
      try {
        let data = new FormData();
            
        if (this.file != null) {
          data.append('file', this.file);
        }
        
        this.formData.alternatives = JSON.stringify(this.formData.alternatives);
        this.formData.remove_alternatives = JSON.stringify(this.formData.remove_alternatives);
    
        for (let param in this.formData) {
          data.append(param, this.formData[param]);
        }

        this.executeLoader = true;
        await this.updateQuestionAction({id, data: data});
        this.toast('Questão alterada com sucesso', 'success');
        this.executeLoader = false;
        return this.closeModal(true);
      } catch(error) {        
        this.executeLoader = false;
        this.toast('Houve um problema para alterar a questão', 'danger');
      }
    },

    closeModal(reload = null) {
      return this.$emit('closeModal', reload)
    },

    addAlternative() {
      this.formData.alternatives.push({
        alternative: "",
        is_correct: false,
      });
    },

    removeAlternative(index, id) {
      this.formData.remove_alternatives.push({
        id: id
      });

      return this.formData.alternatives.splice(index, 1);
    },

    initiateFileObject() {
      if ( document.getElementById('file').value ) {
        document.getElementById('file').value = '';
        document.getElementById('i5-image-upload-list-id').innerHTML = '';
      }
    },

    handleFileObject(event) {
      if (event) {
        this.initiateFileObject();
        this.$refs.file.files = event.clipboardData.files;
      }

      var files = this.$refs.file.files;
      var imageUploadList = document.getElementById('i5-image-upload-list-id');

      if (files.length > 0) {

        for (let i = 0; i < files.length; i++) {
          this.file = files[i];

          let fileReader = new FileReader();
          let fileName = files[i].name;
          let fileSize = this.formatSize(files[i].size);

          let item = document.createElement('li');
              item.setAttribute('class', i);
          
          fileReader.readAsDataURL(files[i]);
          fileReader.onload = function(file) {
            item.innerHTML = '\
              <div class="image">\
                <img src="' + file.target.result + '" border="0">\
              </div>\
              <div class="info">\
                <div>' + fileName + '</div>\
                <div>Tamanho: ' + fileSize + '</div>\
              </div>';
          }
          
          imageUploadList.appendChild(item);
        }

      }
    },

    removeFile() {
      this.formData.image = '';
    },

    getInstitutionId(institutionId) {
      this.reduceYears++;
      return this.formData.institution_id = institutionId
    },

    getMedicineAreaId(medicineAreaId) {
      this.reduceSpecialties++;
      return this.formData.medicine_area_id = medicineAreaId
    },

    getThemeId(themeId) {
      return this.formData.theme_id = themeId
    },

    getYearId(yearId) {
      return this.formData.year_id = yearId
    },

    getSpecialtyId(specialtyId) {
      this.reduceThemes++;
      return this.formData.specialty_id = specialtyId
    },

    toast(text, type, description = '') {
      return createToast(
        {
          title: text,
          description: description,
        },
        {
          type: type,
          transition: 'zoom',
          timeout: 3000,
      })
    },

    formatSize(size) {
      if (size < 1024) {
        return size + 'bytes';
      }

      if (size > 1024 && size < 1048576) {
        return (size/1024).toFixed(1) + 'kb';
      }

      if (size > 1048576) {
        return (size/1048576).toFixed(1) + 'mb';
      }
    },

    dragover(event) {
      event.preventDefault();

      if (!event.currentTarget.classList.contains('i5-image-upload-predrop')) {
        event.currentTarget.classList.add('i5-image-upload-predrop');
      }
    },

    dragleave(event) {
      event.currentTarget.classList.remove('i5-image-upload-predrop');
    },

    drop(event) {
      event.preventDefault();

      this.initiateFileObject();
      this.$refs.file.files = event.dataTransfer.files;
      this.handleFileObject()

      event.currentTarget.classList.remove('i5-image-upload-predrop');
    }

  }
}
</script>