import axios from 'axios';
import { URI_BASE, TOKEN } from '@/config/api';

const routes = {
  specialties: 'specialties/all',
  specialtyThemes: 'specialties/specialty/themes',
  store: 'specialties/store',
  update: 'specialties/update',
  destroy: 'specialties/destroy',
  search: 'specialties/search'
};

export default {
  async getSpecialtiesAction({commit}) {
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }

    const specialties = await axios.get(`${URI_BASE}${routes.specialties}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    commit('SPECIALTIES', specialties.data);

    return specialties;
  },

  async getSpecialtyThemesAction({commit}, specialtyId) {
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }

    return await axios.get(`${URI_BASE}${routes.specialtyThemes}/${specialtyId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
  },

  async storeSpecialtyAction({commit}, data) {    
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }

    return await axios.post(`${URI_BASE}${routes.store}`, data, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
  },

  async updateSpecialtyAction({commit}, params) {
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }

    return await axios.put(`${URI_BASE}${routes.update}/${params.id}`, params.data, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
  },

  async destroySpecialtyAction({commit}, id) {
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }
  
    return await axios.delete(`${URI_BASE}${routes.destroy}/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
  },

  async searchSpecialtiesAction({commit}, params) { 
    const token = localStorage.getItem(TOKEN);

    if (!token) {
      Promise.reject();
    }

    const specialties = await axios.get(`${URI_BASE}${routes.search}?name=${params.name}&medicine_area_id=${params.medicine_area_id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    commit('SPECIALTIES', specialties.data);

    return specialties;
  },
}