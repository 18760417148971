<template>
  <div class="i5-modal i5-modal-ativo">
    <div class="i5-modal-loading" style="display:none;">
      <div class="preloader-wrapper big active">
        <div class="spinner-layer">
          <div class="circle-clipper left"><div class="circle"></div></div>
          <div class="gap-patch"><div class="circle"></div></div>
          <div class="circle-clipper right"><div class="circle"></div></div>
        </div>
      </div>
    </div>

    <div class="i5-modal-wrap in">
      <div class="i5-modal-fechar" title="fechar" @click.prevent="closeModal"><i class="fas fa-times"></i></div>
      <div class="i5-modal-header">instituições vinculadas ao ano: {{ year.name }}</div>
      <div class="i5-modal-content">
        <div class="i5-contents i5-contents-list2">
          <ul>
            <li v-for="(institution, key) in institutions" :key="key">
              {{ institution.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="i5-modal-controls">
        <div class="i5-buttons i5-buttons-right">
          <router-link :to="{name: 'institutions', query: {yearId: year.id}}" class="button adicionar waves-effect">Ir para Institutioções</router-link>
          <button @click.prevent="closeModal" class="button default cancelar waves-effect">Fechar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default {
  props: {
    year: {},
  },

  emits: {
    closeModal: null,
  },

  data() {
    return {
      errors: null,
      success: '',
      institutions: null,
    }
  },

  mounted() {
    if(this.year == {}) {
      return this.toast('Ano não informado', 'danger');
    }

    return this.getInstitutionsByYear(this.year);
  },

  methods: {
    ...mapActions([
      'getInstitutionsByYearAction',
    ]),

    closeModal()  {
      this.$emit('closeModal');
    },

    async getInstitutionsByYear(year) {
      try {
        const response = await this.getInstitutionsByYearAction(year.id);
        this.institutions = response.data;
      } catch(error) {
        return this.toast('Houve um erro ao buscar as instituições', 'danger');
      }
    },

    toast(text, type) {
      return createToast(
        {
          title: text,
          // description: 'teste'
        },
        {
          type: type,
          transition: 'zoom',
          timeout: 3000,
      })
    }
  },
}
</script>