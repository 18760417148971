<template>
  <loader 
    v-if="executeLoader"
  />
  
  <div class="i5-breadcrumb">
    <ul>
      <li>você está em:</li>
      <li>Temas</li>
    </ul>
  </div>

  <div style="margin-top:25px;">
    <themes-filter
      @cleanFilter="cleanFilter"
      @showLoader="showLoader"
      @hideLoader="hideLoader"
    />
  </div>
  
  <themes-list
    :key="filterCleaner"
    :getThemesAfterFilter="getThemesAfterFilter"
    @showLoader="showLoader"
    @hideLoader="hideLoader"
  />
</template>

<script>
import ThemesList from '@/components/themes/ThemesList.vue';
import ThemesFilter from '@/components/themes/ThemesFilter.vue';
import Loader from '@/components/loader/Loader.vue';

export default {
  name: "ThemesView",

  data() {
    return {
      filterCleaner: 0,
      getThemesAfterFilter: false,
      executeLoader: false,
      test: {
        cookie: null
      }
    }
  },

  components: {
    ThemesList,
    ThemesFilter,
    Loader,
  },
  
  mounted() {
    if (this.$route.query.hasOwnProperty('specialtyId')) {
    }
  },

  methods: {
    cleanFilter() {
      this.getThemesAfterFilter = true;
      this.filterCleaner++
    },

    showLoader() {
      return this.executeLoader = true;
    },

    hideLoader() {
      return this.executeLoader = false;
    }
  }
}
</script>