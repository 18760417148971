import axios from 'axios';
import { URI_BASE, TOKEN } from '@/config/api';

const routes = {
    getAll: 'mock-tests',
    getById: 'mock-tests/get-by-id',
    store: 'mock-tests/store',
    update: 'mock-tests/update',
    destroy: 'mock-tests/destroy',
    searchQuestions: 'mock-tests/search-questions',
    searchQuestionsByMockTestId: 'mock-tests/search-questions-by-id',
    storeQuestion: 'mock-tests/questions/store',
    destroyQuestion: 'mock-tests/questions/destroy'
}

export default {
    async getMockTestsAction({commit}) { 
        const token = localStorage.getItem(TOKEN);
    
        if (!token) {
          return Promise.reject({response: {status: 401}});
        }
    
        const mockTests = await axios.get(`${URI_BASE}${routes.getAll}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });
    
        commit('MOCK_TESTS', mockTests.data);
    
        return mockTests;
    },

    async getMockTestAction({commit}, testId) {
      const token = localStorage.getItem(TOKEN);
  
      if (!token) {
        return Promise.reject(401);
      }
  
      return await axios.get(`${URI_BASE}${routes.getById}/${testId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
    },

    async storeMockTestAction({commit}, data){
        const token = localStorage.getItem(TOKEN);

        if (!token) {
            Promise.reject();
        }

        return await axios.post(`${URI_BASE}${routes.store}`, data, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
    },

    async updateMockTestAction({commit}, params) {
        const token = localStorage.getItem(TOKEN);
    
        if (!token) {
          Promise.reject();
        }
    
        return await axios.put(`${URI_BASE}${routes.update}/${params.id}`, params.data, {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });
    },

    async destroyMockTestAction({commit}, id) {
      const token = localStorage.getItem(TOKEN);
  
      if (!token) {
        Promise.reject();
      }
    
      return await axios.delete(`${URI_BASE}${routes.destroy}/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
    },

    async searchQuestionsForMockTestAction({commit}, params) {
      const token = localStorage.getItem(TOKEN);
  
      if (!token) {
        Promise.reject();
      }
      
      const questions = await axios.get(`${URI_BASE}${routes.searchQuestions}?
        question=${params.question}&
        theme_id=${params.theme_id}&
        institution_id=${params.institution_id}&
        specialty_id=${params.specialty_id}&
        year_id=${params.year_id}&
        medicine_area_id=${params.medicine_area_id}&
        test_id=${params.test_id}&
        mock_test_id=${params.mock_test_id}&
        perPage=${params.perPage}&
        `
        , {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
  
      commit('QUESTIONS', questions.data);
  
      return questions;
    },

    async storeQuestionForMockTestAction({commit}, data){
      const token = localStorage.getItem(TOKEN);

      if (!token) {
          Promise.reject();
      }

      return await axios.post(`${URI_BASE}${routes.storeQuestion}`, data, {
          headers: {
              'Authorization': `Bearer ${token}`,
          }
      });
    },

    async destroyQuestionForMockTestAction({commit}, data){
      const token = localStorage.getItem(TOKEN);

      if (!token) {
          Promise.reject();
      }

      return await axios.post(`${URI_BASE}${routes.destroyQuestion}`, data, {
          headers: {
              'Authorization': `Bearer ${token}`,
          }
      });
    },

    async getQuestionsByMockTestIdAction({commit}, params){
      const token = localStorage.getItem(TOKEN);
  
      if (!token) {
        Promise.reject();
      }
      
      const questions = await axios.get(`${URI_BASE}${routes.searchQuestionsByMockTestId}?
        question=${params.question}&
        theme_id=${params.theme_id}&
        institution_id=${params.institution_id}&
        specialty_id=${params.specialty_id}&
        year_id=${params.year_id}&
        medicine_area_id=${params.medicine_area_id}&
        test_id=${params.test_id}&
        mock_test_id=${params.mock_test_id}&
        perPage=${params.perPage}
        `
        , {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
  
      commit('QUESTIONS', questions.data);
  
      return questions;
    }
}