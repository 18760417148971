<template>
  <div class="i5-controls">
    <div class="i5-buttons i5-buttons-superior">
      <button @click.prevent="openModalCreateUpdateInstitution" class="button adicionar waves-effect">Adicionar novo</button>
    </div>
  </div>

  <div class="build-content i5_animated i5_fadeInRight">
    <div class="build-datagrid-default">
      <ul>
        
        <li v-for="(institution, key) in institutionsState.institutions" :key="key">
          <div>
            <div class="info">
              <div>
                <span>ID:</span>
                <span>{{ institution.id }}</span>
              </div>

              <div>
                <span>Título:</span>
                <span>{{ institution.name }}</span>
              </div>
            </div>
    
            <div class="i5-controls-default">
              <button @click.prevent="openModalCreateUpdateInstitution(institution)" title="Editar" class="editar waves-effect"><i class="fas fa-pencil-alt"></i> Editar</button>
              <button @click.prevent="destroyInstitution(institution.id)" title="Remover" class="remover waves-effect"><i class="fas fa-times"></i> Excluir</button>
            </div>
          </div>
        </li>

      </ul>
    </div>
  </div>

  <create-update-institutions-modal 
    v-if="modalCreateUpdateInstitution == true"
    :institution="institution"
    @closeModal="closeModalCreateUpdateInstitution"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

import CreateUpdateInstitutionsModal from '@/components/institutions/CreateUpdateInstitutionsModal.vue';

export default {
  name: 'InstitutionsListComponent',

  props: {
    getInstitutionsAfterFilter: false,
  },

  emits: {
    showLoader: null,
    hideLoader: null,
  },

  data() {
    return {
      formData: {
        name: '',
      },
      errors: null,
      modalCreateUpdateInstitution: false,
      institution: {},
    }
  },

  components: {
    CreateUpdateInstitutionsModal,
  },

  mounted() {
    if (this.institutionsState.institutions.length == 0 || this.getInstitutionsAfterFilter) {
      this.getInstitutions()
    }
  },

  computed: {
    ...mapState({
      institutionsState: state => state.institutions,
    }),
  },

  methods: {
    ...mapActions([
      'getInstitutionsAction',
      'destroyInstitutionAction',
    ]),

    async getInstitutions() {
      try {
        this.showLoaderEmiter();
        return await this.getInstitutionsAction();
      } catch(error) {
        this.toast('Houve um erro ao buscar as institutições', 'danger');
      
      } finally {
        this.hideLoaderEmiter();
      }
    },

    async destroyInstitution(id) {
      try {
        this.showLoaderEmiter();
        await this.destroyInstitutionAction(id)
        this.toast('Tema exlcuído com sucesso', 'success');
        return this.getInstitutions();
      } catch(error) {
        return this.toast('Houve um erro ao excluir o tema', 'danger');
      
      } finally {
        this.hideLoaderEmiter();
      }
    },

    openModalCreateUpdateInstitution(institution) {      
      this.institution = institution;
      this.modalCreateUpdateInstitution = true;
    },

    closeModalCreateUpdateInstitution(reload = null) {
      this.modalCreateUpdateInstitution = false;
      this.institution = {};

      if (reload) {
        return this.getInstitutions();
      }
    },

    showLoaderEmiter() {
      return this.$emit('showLoader')
    },

    hideLoaderEmiter() {
      return this.$emit('hideLoader');
    },

    toast(text, type, description = '') {
      return createToast(
        {
          title: text,
          description: description,
        },
        {
          type: type,
          transition: 'zoom',
          timeout: 3000,
      })
    }
  }
}
</script>