<template>
  <loader
    v-if="executeLoader"
  />

  <div class="i5-breadcrumb">
    <ul>
      <li>você está em:</li>
      <li>Lista de questões:</li>
    </ul>
  </div>

  <div style="margin-top:25px;">
    <questions-filter
      @cleanFilter="cleanFilter"
      @showLoader="showLoader"
      @hideLoader="hideLoader"
    />
  </div>
  
  <questions-list
    :key="filterCleaner"
    :getQuestionsAfterFilter="getQuestionsAfterFilter"
    @showLoader="showLoader"
    @hideLoader="hideLoader"
  />
</template>

<script>
import QuestionsFilter from '@/components/questions/QuestionsFilter.vue';
import QuestionsList from '@/components/questions/QuestionsList.vue';
import Loader from '@/components/loader/Loader.vue';

export default {
  data() {
    return {
      filterCleaner: 0,
      getQuestionsAfterFilter: false,
      executeLoader: false,
    }
  },

  components: {
    QuestionsFilter,
    QuestionsList,
    Loader,
  },

  methods: {
    cleanFilter() {
      this.getQuestionsAfterFilter = true;
      this.filterCleaner++
    },

    showLoader() {
      return this.executeLoader = true;
    },

    hideLoader() {
      return this.executeLoader = false;
    },
  },
}
</script>