<template>
  <loader
    v-if="executeLoader"
  />

  <div class="i5-breadcrumb">
    <ul>
      <li>você está em:</li>
      <li>Especialidades</li>
    </ul>
  </div>
  <specialties-filter
    @cleanFilter="cleanFilter"
    @showLoader="showLoader"
    @hideLoader="hideLoader"
  />

  <specialties-list
    :key="filterCleaner"
    :getSpecialtiesAfterFilter="getSpecialtiesAfterFilter"
    @showLoader="showLoader"
    @hideLoader="hideLoader"
  />
</template>
<script>
import SpecialtiesList from '@/components/specialties/SpecialtiesList.vue';
import SpecialtiesFilter from '@/components/specialties/SpecialtiesFilter.vue';
import Loader from '@/components/loader/Loader.vue';

export default {
  name: "SpecialtiesView",

  data() {
    return {
      filterCleaner: 0,
      getSpecialtiesAfterFilter: false,
      executeLoader: false,
    }
  },

  components: {
    SpecialtiesList,
    SpecialtiesFilter,
    Loader,
  },
  
  mounted() {
    if (this.$route.query.hasOwnProperty('specialtyId')) {
    }
  },

  methods: {
    cleanFilter() {
      this.getSpecialtiesAfterFilter = true;
      this.filterCleaner++
    },

    showLoader() {
      return this.executeLoader = true;
    },

    hideLoader() {
      return this.executeLoader = false;
    }
  }
}
</script>