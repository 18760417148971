<template>
  <label for="specialties">Especialidades: </label>
  <select
    id="specialties"
    name="specialties"
    v-model="formData.specialty_id"
    @change="sendSpecialtyId"
  >
    <option :value="specialty">Escolha...</option>
    <option
      :value="specialty.id"
      v-for="(specialty, key) in recievedMedicineAreaId == 0 || recievedMedicineAreaId == undefined ? specialtiesState.specialties : reducedSpecialtiesByMedicineArea"
      :key="key"
    >
      {{ specialty.name }}
    </option>
  </select>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'SelectSpecialties',

  props: {
    recievedSpecialtyId: 0,
    recievedMedicineAreaId: 0,
    filter: 0,
  },

  emits: {
    sendSpecialtyId: null,
    sendThemeId: null,
  },

  mounted() {
    if (Object.keys(this.specialtiesState.specialties).length == 0) {
      this.getSpecialties();
    }

    this.fetchRecievedData();

    if (this.recievedMedicineAreaId != 0 && this.recievedMedicineAreaId != undefined) {
      this.reduceByMedicineArea();
    }
  },

  data() {
    return {
      formData: {
        specialty_id: 0,
      },

      reducedSpecialtiesByMedicineArea: [],

      theme: 0,
      specialty: 0
    }
  },

  computed: {
    ...mapState({
      specialtiesState: state => state.specialties,
    }),
  },
  
  methods: {
    ...mapActions([
      'getSpecialtiesAction',
    ]),

    fetchRecievedData() {
      return this.formData.specialty_id = this.recievedSpecialtyId;
    },

    reduceByMedicineArea() {
      this.specialtiesState.specialties.map(specialty => {
        if (specialty.medicine_area_id == this.recievedMedicineAreaId) {
          this.reducedSpecialtiesByMedicineArea.push(specialty);
        }
      })
    },

    sendThemeId() {
      this.$emit('sendThemeId', this.formData.themesId);
    },

    async getSpecialties() {
      try {
        return await this.getSpecialtiesAction();

      } catch(error) {
        return this.errors = error;
      }
    },

    sendSpecialtyId() {
      this.$emit('sendSpecialtyId', this.formData.specialty_id)
    }
  },
}
</script>