<template>
  <div class="i5-modal i5-modal-ativo">
    <div class="i5-modal-wrap in">
      <div class="i5-modal-fechar" title="fechar" @click.prevent="closeModal"><i class="fas fa-times"></i></div>
      <div class="i5-modal-header">{{ specialty.id == null ? 'Nova Especialidades' : 'Atualizar Especialidades' }}</div>
      <div class="i5-modal-content">
        
        <div class="form-grid">
          <div class="input-12 required">
            <label for="specialty_id">Título</label>
            <input type="text" name="specialty_name" id="specialty_id" v-model="formData.name">
          </div>
        </div>
        
        <div class="form-grid">
          <div class="input-12 required">
            <select-medicine-area
              :recievedMedicineAreaId="specialty.medicine_area_id"
              @sendMedicineAreaId="sendMedicineAreaId"
            />
          </div>
        </div>

      </div>
      
      <div class="i5-modal-controls">
        <div class="i5-buttons i5-buttons-right">
          <button class="button adicionar waves-effect" @click.prevent="specialty.id == null ? storeSpecialty() : updateSpecialty(specialty.id)">{{ specialty.id == null ? 'Salvar' : 'Atualizar' }}</button>
          <button @click.prevent="closeModal" class="button default cancelar waves-effect">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

import SelectMedicineArea from '@/components/medicineAreas/SelectMedicineAreas.vue';

export default {
  name: 'CreateUpdateSpecialtiesModal',

  props: {
    specialty: {
      id: null,
      name: '',
      medicine_area_id: null,
    },
  },

  emits: {
    closeModal: null,
  },

  components: {
    SelectMedicineArea,
  },

  data() {
    return {
      formData: {
        id: null,
        name: '',
        medicine_area_id: null,
      }
    }
  },

  mounted() {
    this.fetchSpecialyData()
  },

  methods: {
    ...mapActions([
      'storeSpecialtyAction',
      'updateSpecialtyAction',
      'destroySpecialtyAction',
    ]),

    fetchSpecialyData() {
      this.formData.id = this.specialty.id;
      this.formData.name = this.specialty.name;
      this.formData.medicine_area_id = this.specialty.medicine_area_id;
    },

    async storeSpecialty() {
      try {
        await this.storeSpecialtyAction(this.formData);
        this.toast('Especialidade cadastrada com sucesso.', 'success');
        return this.closeModal(true);
      } catch(error) {
        return this.toast('Houve um erro ao cadastrar a especialidade.', 'danger');
      }
    },

    async updateSpecialty(id) {
      try {
        await this.updateSpecialtyAction({id, data: this.formData});
        this.toast('Especialidade alterada com sucesso', 'success');
        return this.closeModal(true);
      } catch(error) {        
        this.toast('Houve um problema para alterar a especialidade', 'danger');
      }
    },

    closeModal(reload = null) {
      this.$emit('closeModal', reload)
    },

    sendMedicineAreaId(id) {
      return this.formData.medicine_area_id = id;
    },

    toast(text, type, description = '') {
      return createToast(
        {
          title: text,
          description: description,
        },
        {
          type: type,
          transition: 'zoom',
          timeout: 3000,
      })
    }
  }
}
</script>