import axios from 'axios';
import { URI_BASE, TOKEN } from '@/config/api';

const routes = {
    users: 'user/all',
    store: 'user/store',
    update: 'user/update',
    destroy: 'user/destroy',
    search: 'user/search',
}

export default {
    async getUsersAction({commit}){
        const token = localStorage.getItem(TOKEN);

        if (!token) {
            Promise.reject();
        }

        const users = await axios.get(`${URI_BASE}${routes.users}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            }
        });
      
        commit('USERS', users.data);
      
        return users;
    },

    async storeUserAction({commit}, data){
        const token = localStorage.getItem(TOKEN);

        if (!token) {
            Promise.reject();
        }

        return await axios.post(`${URI_BASE}${routes.store}`, data, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
    },

    async updateUserAction({commit}, data){
        const token = localStorage.getItem(TOKEN);

        if (!token) {
            Promise.reject();
        }
        
        return await axios.post(`${URI_BASE}${routes.update}/${data.id}`, data, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
    },

    async destroyUserAction({commit}, id) {
        const token = localStorage.getItem(TOKEN);
    
        if (!token) {
            Promise.reject();
        }
      
        return await axios.delete(`${URI_BASE}${routes.destroy}/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
    },

    async searchUserAction({commit}, params) {
        const token = localStorage.getItem(TOKEN);

        if (!token) {
        Promise.reject();
        }

        const users = await axios.get(`${URI_BASE}${routes.search}?name=${params.name}&active=${params.active}&email=${params.email}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
        });

        commit('USERS', users.data);

        return users;
    }
}